var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Logo from "../../assets/img/logo.svg";
var Footer = function () {
    return (_jsx("div", __assign({ className: "bg-white" }, { children: _jsxs("div", __assign({ className: "max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8" }, { children: [_jsx("div", __assign({ className: "xl:grid xl:grid-cols-3 xl:gap-8" }, { children: _jsxs("div", __assign({ className: "xl:col-span-1" }, { children: [_jsx("img", { className: "h-10", src: Logo, alt: "DomainPage.io" }, void 0), _jsx("p", __assign({ className: "ml-3 text-xs leading-6 text-gray-400" }, { children: "The Domain Investor Platform." }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "mt-12 border-t border-gray-200 pt-8" }, { children: _jsxs("p", __assign({ className: "text-xs text-center leading-6 text-gray-400" }, { children: ["\u00A9 ".concat(new Date().getFullYear()), " DomainPage.io | All Rights Reserved |", " ", _jsx("a", __assign({ href: "/privacy-policy", target: "_blank" }, { children: "Privacy Policy" }), void 0), " ", "|", " ", _jsx("a", __assign({ href: "/cookie-policy", target: "_blank" }, { children: "Cookie Policy" }), void 0), " ", "|", " ", _jsx("a", __assign({ href: "/acceptable-use-policy", target: "_blank" }, { children: "Acceptable Use Policy" }), void 0), " ", "|", " ", _jsx("a", __assign({ href: "/terms-of-service", target: "_blank" }, { children: "Terms of Service" }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default Footer;
