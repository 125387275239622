var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var SlimCardSkeleton = function () {
    return (_jsxs("div", __assign({ className: "animate-pulse border border-gray-200 bg-white px-4 py-3 sm:px-6 shadow-md rounded-md hover:shadow-xs transition:shadow ease-in-out duration-300" }, { children: [_jsx("div", __assign({ className: "flex space-x-3 justify-center" }, { children: _jsx("div", __assign({ className: "items-center flex min-w-0 flex-1" }, { children: _jsx("div", { className: "h-4 bg-gray-200 rounded w-full rounded-md" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "mt-2 items-center flex min-w-0 flex-1" }, { children: _jsx("div", { className: "h-4 bg-gray-200 rounded w-3/4 rounded-md" }, void 0) }), void 0)] }), void 0));
};
export default SlimCardSkeleton;
