var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import AppLink from "../../components/AppLink";
import CurrencyCell from "../../components/table/CurrencyCell";
import RegistrarDisplayCell from "../../components/table/RegistrarDisplayCell";
import { handleDateFormatting } from "../../utils";
import { Header } from "@domainpage/shared/src/constants/headers";
import { Redirect } from "@domainpage/shared/src/constants/menu";
export var expirationsTableColumnSchema = [
    {
        id: Header.NAME_COLUMN,
        Header: Header.NAME_DISPLAY,
        accessor: Header.NAME_COLUMN,
        Cell: function (_a) {
            var cell = _a.cell;
            return (_jsx(AppLink, __assign({ className: "w-full h-full", to: "".concat(Redirect.DOMAINS, "/").concat(cell.value) }, { children: cell.value }), void 0));
        }
    },
    {
        id: Header.EXPIRATION_COLUMN,
        Header: Header.EXPIRATION_DISPLAY,
        accessor: Header.EXPIRATION_COLUMN,
        Cell: function (_a) {
            var cell = _a.cell;
            return handleDateFormatting(cell.value);
        }
    },
    {
        id: Header.RENEWAL_COST_COLUMN,
        Header: Header.RENEWAL_COST_DISPLAY,
        accessor: Header.RENEWAL_COST_COLUMN,
        Cell: CurrencyCell
    },
    {
        id: Header.PURCHASE_DATE_COLUMN,
        Header: Header.PURCHASE_DATE_DISPLAY,
        accessor: Header.PURCHASE_DATE_COLUMN,
        Cell: function (_a) {
            var cell = _a.cell;
            return handleDateFormatting(cell.value);
        }
    },
    {
        id: Header.REGISTRAR_COLUMN,
        Header: Header.REGISTRAR_DISPLAY,
        accessor: Header.REGISTRAR_COLUMN,
        Cell: RegistrarDisplayCell
    },
];
