import { Plan } from "./plan";
export var Feature;
(function (Feature) {
    Feature["DOMAIN_PORTFOLIO"] = "DOMAIN_PORTFOLIO";
    Feature["DOMAIN_PAGE"] = "DOMAIN_PAGE";
    Feature["BULK_DOMAIN_UPLOAD"] = "BULK_DOMAIN_UPLOAD";
    Feature["REGISTRAR_API_INTEGRATION"] = "REGISTRAR_API_INTEGRATION";
    Feature["GOOGLE_ANALYTICS_INTEGRATION"] = "GOOGLE_ANALYTICS_INTEGRATION";
    Feature["GOOGLE_RECAPTCHA"] = "GOOGLE_RECAPTCHA";
    Feature["DOMAIN_FORWARDING"] = "DOMAIN_FORWARDING";
    Feature["DOMAIN_LOCK"] = "DOMAIN_LOCK";
    Feature["LEAD_CAPTURE"] = "LEAD_CAPTURE";
    Feature["DOMAIN_PAGE_VIEWS"] = "DOMAIN_PAGE_VIEWS";
    Feature["THIRD_PARTY_DATA_ENCRYPTION"] = "THIRD_PARTY_DATA_ENCRYPTION";
    Feature["CUSTOM_DOMAIN_PAGE_LOGO"] = "CUSTOM_DOMAIN_PAGE_LOGO";
    Feature["DOMAIN_METADATA"] = "DOMAIN_METADATA";
    Feature["DOMAIN_PAYMENT_LINKS"] = "DOMAIN_PAYMENT_LINKS";
    Feature["DOMAIN_PUBLIC"] = "DOMAIN_PUBLIC";
    Feature["ESCROW_BUY_IT_NOW"] = "ESCROW_BUY_IT_NOW";
    Feature["DOMAIN_PORTFOLIO_METRICS"] = "DOMAIN_PORTFOLIO_METRICS";
    Feature["DOMAIN_PAGE_METRICS"] = "DOMAIN_PAGE_METRICS";
    Feature["CSV_DATA_EXPORT"] = "CSV_DATA_EXPORT";
    Feature["JSON_DATA_EXPORT"] = "JSON_DATA_EXPORT";
})(Feature || (Feature = {}));
export var FeatureLimit;
(function (FeatureLimit) {
    // STANDARD
    FeatureLimit[FeatureLimit["UNLIMITED"] = Infinity] = "UNLIMITED";
    FeatureLimit[FeatureLimit["DOMAIN_PAGE"] = 1] = "DOMAIN_PAGE";
    FeatureLimit[FeatureLimit["DOMAIN_PORTFOLIO"] = 1] = "DOMAIN_PORTFOLIO";
    FeatureLimit[FeatureLimit["CUSTOM_DOMAIN_PAGE_LOGO"] = 1] = "CUSTOM_DOMAIN_PAGE_LOGO";
    FeatureLimit[FeatureLimit["THIRD_PARTY_DATA_ENCRYPTION"] = 1] = "THIRD_PARTY_DATA_ENCRYPTION";
    FeatureLimit[FeatureLimit["BULK_DOMAIN_UPLOAD_LIMIT"] = 5000] = "BULK_DOMAIN_UPLOAD_LIMIT";
    FeatureLimit[FeatureLimit["DOMAIN_PAGE_VIEWS"] = 1] = "DOMAIN_PAGE_VIEWS";
    FeatureLimit[FeatureLimit["GOOGLE_RECAPTCHA"] = 1] = "GOOGLE_RECAPTCHA";
    FeatureLimit[FeatureLimit["DOMAIN_METADATA"] = 1] = "DOMAIN_METADATA";
    FeatureLimit[FeatureLimit["DOMAIN_PUBLIC"] = 1] = "DOMAIN_PUBLIC";
    FeatureLimit[FeatureLimit["ESCROW_BUY_IT_NOW"] = 1] = "ESCROW_BUY_IT_NOW";
    // PLAN ONE
    FeatureLimit[FeatureLimit["PLAN_ONE_DOMAIN_PAGE_LIMIT"] = 100] = "PLAN_ONE_DOMAIN_PAGE_LIMIT";
    FeatureLimit[FeatureLimit["PLAN_ONE_LEAD_CAPTURE_LIMIT"] = 50] = "PLAN_ONE_LEAD_CAPTURE_LIMIT";
    FeatureLimit[FeatureLimit["PLAN_ONE_DOMAIN_PAYMENT_LINKS"] = 1] = "PLAN_ONE_DOMAIN_PAYMENT_LINKS";
    FeatureLimit[FeatureLimit["PLAN_ONE_GOOGLE_ANALYTICS_INTEGRATION"] = 0] = "PLAN_ONE_GOOGLE_ANALYTICS_INTEGRATION";
    FeatureLimit[FeatureLimit["PLAN_ONE_REGISTRAR_API_INTEGRATION"] = 0] = "PLAN_ONE_REGISTRAR_API_INTEGRATION";
    FeatureLimit[FeatureLimit["PLAN_ONE_DOMAIN_FORWARDING"] = 0] = "PLAN_ONE_DOMAIN_FORWARDING";
    FeatureLimit[FeatureLimit["PLAN_ONE_DOMAIN_LOCK"] = 0] = "PLAN_ONE_DOMAIN_LOCK";
    // PLAN TWO
    FeatureLimit[FeatureLimit["PLAN_TWO_DOMAIN_PAGE_LIMIT"] = 5000] = "PLAN_TWO_DOMAIN_PAGE_LIMIT";
    FeatureLimit[FeatureLimit["PLAN_TWO_LEAD_CAPTURE_LIMIT"] = 2500] = "PLAN_TWO_LEAD_CAPTURE_LIMIT";
    FeatureLimit[FeatureLimit["PLAN_TWO_GOOGLE_ANALYTICS_INTEGRATION"] = 1] = "PLAN_TWO_GOOGLE_ANALYTICS_INTEGRATION";
    FeatureLimit[FeatureLimit["PLAN_TWO_DOMAIN_PAYMENT_LINKS"] = 1] = "PLAN_TWO_DOMAIN_PAYMENT_LINKS";
    FeatureLimit[FeatureLimit["PLAN_TWO_REGISTRAR_API_INTEGRATION"] = 0] = "PLAN_TWO_REGISTRAR_API_INTEGRATION";
    FeatureLimit[FeatureLimit["PLAN_TWO_DOMAIN_FORWARDING"] = 0] = "PLAN_TWO_DOMAIN_FORWARDING";
    FeatureLimit[FeatureLimit["PLAN_TWO_DOMAIN_LOCK"] = 0] = "PLAN_TWO_DOMAIN_LOCK";
})(FeatureLimit || (FeatureLimit = {}));
export var Features = [
    {
        plan: Plan.PLAN_ONE,
        features: [
            { feature: Feature.DOMAIN_PAGE, limit: FeatureLimit.PLAN_ONE_DOMAIN_PAGE_LIMIT },
            { feature: Feature.LEAD_CAPTURE, limit: FeatureLimit.PLAN_ONE_LEAD_CAPTURE_LIMIT },
            {
                feature: Feature.GOOGLE_ANALYTICS_INTEGRATION,
                limit: FeatureLimit.PLAN_ONE_GOOGLE_ANALYTICS_INTEGRATION,
            },
            { feature: Feature.DOMAIN_PAYMENT_LINKS, limit: FeatureLimit.PLAN_ONE_DOMAIN_PAYMENT_LINKS },
            { feature: Feature.DOMAIN_PUBLIC, limit: FeatureLimit.DOMAIN_PUBLIC },
            // { feature: Feature.REGISTRAR_API_INTEGRATION, limit: FeatureLimit.PLAN_ONE_REGISTRAR_API_INTEGRATION },
            // { feature: Feature.DOMAIN_FORWARDING, limit: FeatureLimit.PLAN_ONE_DOMAIN_FORWARDING },
            { feature: Feature.DOMAIN_LOCK, limit: FeatureLimit.PLAN_ONE_DOMAIN_LOCK },
            { feature: Feature.DOMAIN_PORTFOLIO, limit: FeatureLimit.DOMAIN_PORTFOLIO },
            { feature: Feature.DOMAIN_METADATA, limit: FeatureLimit.DOMAIN_METADATA },
            { feature: Feature.DOMAIN_PAGE_VIEWS, limit: FeatureLimit.DOMAIN_PAGE_VIEWS },
            { feature: Feature.BULK_DOMAIN_UPLOAD, limit: FeatureLimit.BULK_DOMAIN_UPLOAD_LIMIT },
            { feature: Feature.CUSTOM_DOMAIN_PAGE_LOGO, limit: FeatureLimit.CUSTOM_DOMAIN_PAGE_LOGO },
            { feature: Feature.THIRD_PARTY_DATA_ENCRYPTION, limit: FeatureLimit.THIRD_PARTY_DATA_ENCRYPTION },
            { feature: Feature.ESCROW_BUY_IT_NOW, limit: FeatureLimit.ESCROW_BUY_IT_NOW },
            { feature: Feature.GOOGLE_RECAPTCHA, limit: FeatureLimit.GOOGLE_RECAPTCHA },
        ],
    },
    {
        plan: Plan.PLAN_TWO,
        features: [
            { feature: Feature.DOMAIN_PAGE, limit: FeatureLimit.PLAN_TWO_DOMAIN_PAGE_LIMIT },
            { feature: Feature.LEAD_CAPTURE, limit: FeatureLimit.PLAN_TWO_LEAD_CAPTURE_LIMIT },
            {
                feature: Feature.GOOGLE_ANALYTICS_INTEGRATION,
                limit: FeatureLimit.PLAN_TWO_GOOGLE_ANALYTICS_INTEGRATION,
            },
            { feature: Feature.DOMAIN_PAYMENT_LINKS, limit: FeatureLimit.PLAN_TWO_DOMAIN_PAYMENT_LINKS },
            { feature: Feature.DOMAIN_PUBLIC, limit: FeatureLimit.DOMAIN_PUBLIC },
            // { feature: Feature.REGISTRAR_API_INTEGRATION, limit: FeatureLimit.PLAN_TWO_REGISTRAR_API_INTEGRATION },
            // { feature: Feature.DOMAIN_FORWARDING, limit: FeatureLimit.PLAN_TWO_DOMAIN_FORWARDING },
            { feature: Feature.DOMAIN_LOCK, limit: FeatureLimit.PLAN_TWO_DOMAIN_LOCK },
            { feature: Feature.DOMAIN_PORTFOLIO, limit: FeatureLimit.DOMAIN_PORTFOLIO },
            { feature: Feature.DOMAIN_METADATA, limit: FeatureLimit.DOMAIN_METADATA },
            { feature: Feature.DOMAIN_PAGE_VIEWS, limit: FeatureLimit.DOMAIN_PAGE_VIEWS },
            { feature: Feature.BULK_DOMAIN_UPLOAD, limit: FeatureLimit.BULK_DOMAIN_UPLOAD_LIMIT },
            { feature: Feature.CUSTOM_DOMAIN_PAGE_LOGO, limit: FeatureLimit.CUSTOM_DOMAIN_PAGE_LOGO },
            { feature: Feature.THIRD_PARTY_DATA_ENCRYPTION, limit: FeatureLimit.THIRD_PARTY_DATA_ENCRYPTION },
            { feature: Feature.ESCROW_BUY_IT_NOW, limit: FeatureLimit.ESCROW_BUY_IT_NOW },
            { feature: Feature.GOOGLE_RECAPTCHA, limit: FeatureLimit.GOOGLE_RECAPTCHA },
        ],
    },
];
