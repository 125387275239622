var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Currency, Interval, LOADING } from "@domainpage/shared/src";
import numeral from "numeral";
export var combineReducers = function (slices) { return function (prevState, action) {
    return Object.keys(slices).reduce(function (nextState, nextProp) {
        var _a;
        return (__assign(__assign({}, nextState), (_a = {}, _a[nextProp] = slices[nextProp](prevState[nextProp], action), _a)));
    }, prevState);
}; };
export function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
}
export var setLoadingFalse = function (dispatch, delay) {
    if (delay === void 0) { delay = 0; }
    setTimeout(function () {
        dispatch({ type: LOADING, payload: false });
    }, delay);
};
export var formatNumberToCurrency = function (value, format) {
    if (format === void 0) { format = Currency.CURRENCY_WITHOUT_DECIMAL; }
    return numeral(value).format(format);
};
export var mapIntervalToIntervalDisplay = function (interval) {
    switch (interval) {
        case Interval.DAY:
            return Interval.DAY_DISPLAY;
        case Interval.WEEK:
            return Interval.WEEK_DISPLAY;
        case Interval.MONTH:
            return Interval.MONTH_DISPLAY;
        case Interval.QUARTER:
            return Interval.QUARTER_DISPLAY;
        case Interval.YEAR:
            return Interval.YEAR_DISPLAY;
    }
    return Interval.QUARTERLY;
};
