import { Plan } from "./plan";
export var planOneFeatures = [
    { description: "Showcase up to ".concat(Plan.PLAN_ONE_DOMAIN_LIMIT, " Domains") },
    { description: "".concat(Plan.PLAN_ONE_LEAD_CAPTURE_LIMIT, " Monthly Leads") },
    { description: "Domain Sales Dashboard & Tools" },
    { description: "Dedicated Domain Pages" },
    { description: "Escrow.com Buy-It-Now Integration" },
    { description: "Domain Page Forms w/ Email Notifications" },
    { description: "Purchase, Finance, & Lease Links for Buyers" },
    { description: "Domain Description Field" },
    { description: "Domain Portfolio Page" },
    { description: "Domain Lead Dashboard" },
    { description: "Domain Page Metrics" },
    { description: "Bulk Domain Upload" },
    { description: "Google reCAPTCHA Spam Prevention" },
];
export var planTwoFeatures = [
    { description: "All ".concat(Plan.PLAN_ONE_DISPLAY, " Plan Features Plus...") },
    { description: "".concat(Plan.PLAN_TWO_DOMAIN_LIMIT, " Domains") },
    { description: "".concat(Plan.PLAN_TWO_LEAD_CAPTURE_LIMIT, " Monthly Leads") },
    { description: "Custom Domain Page Logo" },
    { description: "Google Analytics Integration" }
];
