var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MIN_PRICE, MAX_PRICE, RESET_RANGE, MAX_LENGTH, MIN_LENGTH } from "../actions";
import { Marketplace } from "@domainpage/shared/src";
var marketplaceReducer = function (state, action) {
    switch (action.type) {
        case MIN_PRICE:
            return __assign(__assign({}, state), { currentMinPrice: action.payload });
        case MAX_PRICE:
            return __assign(__assign({}, state), { currentMaxPrice: action.payload });
        case MIN_LENGTH:
            return __assign(__assign({}, state), { currentMinLength: action.payload });
        case MAX_LENGTH:
            return __assign(__assign({}, state), { currentMaxLength: action.payload });
        case RESET_RANGE:
            return __assign(__assign({}, state), { currentMinPrice: Marketplace.STARTING_MIN_PRICE, currentMaxPrice: Marketplace.STARTING_MAX_PRICE, currentMinLength: Marketplace.STARTING_MIN_LENGTH, currentMaxLength: Marketplace.STARTING_MAX_LENGTH });
        default:
            return state;
    }
};
export default marketplaceReducer;
