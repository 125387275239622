import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Redirect, Route } from "react-router-dom";
var ProtectedRoute = function (_a) {
    var component = _a.component, auth = _a.auth, path = _a.path, authenticated = _a.authenticated, _b = _a.exact, exact = _b === void 0 ? false : _b;
    return authenticated ? (
    // @ts-ignore
    _jsx(Route, { component: component, exact: exact, path: path, auth: auth.user }, void 0)) : (_jsx(Redirect, { to: "/login" }, void 0));
};
export default ProtectedRoute;
