var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { ViewGridOutline, ViewListOutline } from "heroicons-react";
import { Marketplace } from "@domainpage/shared/src";
var LayoutButtonGroup = function (_a) {
    var selected = _a.selected, callback = _a.callback;
    var _b = useState(selected), isSelected = _b[0], setIsSelected = _b[1];
    var handleSelect = function (layout) {
        setIsSelected(layout);
        callback(layout);
    };
    return (_jsxs("span", __assign({ className: "relative z-0 inline-flex shadow-sm rounded-md m-1 sm:m-0" }, { children: [_jsxs("button", __assign({ onClick: function () { return handleSelect(Marketplace.LAYOUT_CARD); }, type: "button", className: "".concat(isSelected === Marketplace.LAYOUT_CARD ? "bg-gray-200" : "bg-white", " relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 active:ring-2") }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Card Layout" }), void 0), _jsx(ViewGridOutline, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0), _jsxs("button", __assign({ type: "button", onClick: function () { return handleSelect(Marketplace.LAYOUT_SLIM_CARD); }, className: "".concat(isSelected === Marketplace.LAYOUT_SLIM_CARD ? "bg-gray-200" : "bg-white", " -ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 active:ring-2") }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Slim Card Layout" }), void 0), _jsx(ViewListOutline, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0)] }), void 0));
};
export default LayoutButtonGroup;
