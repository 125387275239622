var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import ReactDatePicker from 'react-date-picker';
var DatePicker = function (_a) {
    var setDateCallback = _a.setDateCallback, name = _a.name, value = _a.value, _b = _a.disableStyle, disableStyle = _b === void 0 ? false : _b, _c = _a.disableCalender, disableCalender = _c === void 0 ? false : _c, _d = _a.disableClear, disableClear = _d === void 0 ? false : _d, _e = _a.openCalendarOnFocus, openCalendarOnFocus = _e === void 0 ? false : _e;
    var defaultDate = !value ? Date.now() : value;
    return (_jsx("div", __assign({ className: "text-gray-500 text-sm ".concat(disableStyle ? "" : "mt-1 flex rounded-md shadow-sm inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-50") }, { children: _jsx(ReactDatePicker, { className: 'domainpage-datepicker', name: name, value: new Date(defaultDate), openCalendarOnFocus: openCalendarOnFocus, calendarIcon: disableCalender ? null : _jsx(CalendarIcon, { className: 'h-4 w-4' }, void 0), clearIcon: disableClear ? null : _jsx(XIcon, { className: 'h-3 w-3' }, void 0), onChange: setDateCallback }, void 0) }), void 0));
};
export default DatePicker;
