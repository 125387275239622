import { NOTIFICATION_DISPLAY, NOTIFICATION_HIDE } from "../actions";
export var emitToastNotification = function (dispatch, notification, displayFor) {
    if (displayFor === void 0) { displayFor = 5000; }
    dispatch({
        type: NOTIFICATION_DISPLAY,
        payload: { title: notification.title, message: notification.message, type: notification.type },
    });
    setTimeout(function () {
        dispatch({ type: NOTIFICATION_HIDE });
    }, displayFor);
};
