var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import React, { Fragment, useContext, useEffect, useState } from "react";
import QueryContext from "../context/QueryContext";
import { handleDateFormatting } from "../utils";
import ButtonLoader from "./common/ButtonLoader";
import MultiSelectDropdown from "./MultiSelectDropdown";
import AuthContext from "../context/AuthContext";
import { Header, Currency, Plan, BulkHeaders, Headers, IgnoredBulkUploadTableHeaders } from "@domainpage/shared/src";
import numeral from "numeral";
var BulkUploadTable = function (_a) {
    var data = _a.data;
    var auth = useContext(AuthContext).state;
    var state = useContext(QueryContext).state;
    var query = state.query;
    var _b = useState([]), headers = _b[0], setHeaders = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var planType = auth.user.planType;
    var userOnFreePlan = planType === Plan.PLAN_ONE;
    var setTableHeadersWithDisplayStatus = function () {
        var headersWithDisplayStatus = Headers.map(function (header) {
            if (IgnoredBulkUploadTableHeaders.includes(header.column)) {
                header.display = false;
            }
            return header;
        });
        setHeaders(headersWithDisplayStatus);
    };
    var renderTableHeaders = function (headers) {
        return headers.map(function (header, key) {
            if (IgnoredBulkUploadTableHeaders.includes(header === null || header === void 0 ? void 0 : header.column))
                return null;
            return (header === null || header === void 0 ? void 0 : header.display) ? (_jsx("th", __assign({ className: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" }, { children: header.displayName }), key)) : null;
        });
    };
    var convertMappedHeaderValue = function (row, header) {
        var rowKeys = Object.keys(row);
        var acceptedHeaderKey = null;
        BulkHeaders[header].mappedColumns.map(function (header) {
            var index = rowKeys.indexOf(header);
            if (index > -1) {
                acceptedHeaderKey = row[rowKeys[index]];
            }
        });
        return acceptedHeaderKey;
    };
    var renderIndividualColumnsForRow = function (domain) {
        var domainCopy = __assign({}, domain);
        var mappedNameValue = convertMappedHeaderValue(domainCopy, Header.NAME);
        var mappedExpirationValue = handleDateFormatting(convertMappedHeaderValue(domainCopy, Header.EXPIRATION));
        return headers.map(function (header, key) {
            if (header.column === Header.DOMAIN_COST_COLUMN || header.column === Header.SELLING_PRICE_COLUMN) {
                domainCopy[header.column] = numeral(domainCopy[header.column]).format(Currency.CURRENCY_WITH_DECIMAL);
            }
            if (IgnoredBulkUploadTableHeaders.includes(header.column)) {
                return null;
            }
            if (header.display) {
                if (header.name === Header.NAME && mappedNameValue) {
                    return (_jsx("td", __assign({ className: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" }, { children: _jsx("div", __assign({ className: "flex" }, { children: _jsx("a", __assign({ href: "https://".concat(auth.user.username, ".").concat(process.env.REACT_APP_ROOT_URL, "/").concat(domain[Header.NAME]), target: "_blank", className: "group inline-flex space-x-2 truncate text-sm" }, { children: _jsx("p", __assign({ className: "text-gray-500 font-bold truncate group-hover:text-gray-900" }, { children: mappedNameValue }), void 0) }), void 0) }), void 0) }), key));
                }
                else if (header.name === Header.EXPIRATION && mappedExpirationValue) {
                    return (_jsx("td", __assign({ className: "px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500" }, { children: _jsx("p", __assign({ className: "text-left" }, { children: mappedExpirationValue }), void 0) }), key));
                }
                else if (header.name === Header.DESCRIPTION) {
                    return (_jsx("td", __assign({ className: "px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500" }, { children: _jsx("p", __assign({ className: "text-left" }, { children: domain[Header.DESCRIPTION] }), void 0) }), key));
                }
                else if (header.name === Header.PUBLIC || header.name === Header.BUY_IT_NOW) {
                    return (_jsx("td", __assign({ className: "px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500" }, { children: _jsx("p", __assign({ className: "text-left" }, { children: domain[header.column] ? (_jsx(CheckCircleIcon, { className: "h-5 w-5 text-green-500" }, void 0)) : (_jsx(XCircleIcon, { className: "h-5 w-5 text-red-500" }, void 0)) }), void 0) }), key));
                }
                else if (domain[header.column]) {
                    return (_jsx("td", __assign({ className: "px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500" }, { children: _jsx("p", __assign({ className: "text-left" }, { children: domain[header.column] }), void 0) }), key));
                }
                else {
                    return (_jsx("td", __assign({ className: "px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500" }, { children: _jsx("p", __assign({ className: "text-left" }, { children: " - " }), void 0) }), key));
                }
            }
        });
    };
    var renderTableRows = function () {
        return data.map(function (domain, key) {
            return (_jsx("tr", __assign({ className: "bg-white" }, { children: renderIndividualColumnsForRow(domain) }), key));
        });
    };
    useEffect(function () {
        setTableHeadersWithDisplayStatus();
    }, []);
    useEffect(function () {
        setTimeout(function () {
            setLoading(false);
        }, 1250);
    }, []);
    return (_jsxs(Fragment, { children: [loading ? (_jsx("div", __assign({ className: "".concat(!loading
                    ? "opacity-0 transition-all ease-out duration-300"
                    : "opacity-100 block transition-all ease-in duration-300", " flex text-center justify-center py-12") }, { children: _jsx(ButtonLoader, {}, void 0) }), void 0)) : null, _jsx(Fragment, { children: _jsxs("div", __assign({ className: "".concat(loading
                        ? "opacity-0 transition-all ease-out duration-300"
                        : "opacity-100 block transition-all ease-in duration-300", " flex flex-col mt-2 m-h-full") }, { children: [_jsx(MultiSelectDropdown, { tableHeadersWithDisplayStatus: headers, setTableHeadersWithDisplayStatus: setHeaders }, void 0), _jsx("div", __assign({ className: "align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg" }, { children: _jsxs("table", __assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx("thead", { children: _jsx("tr", { children: renderTableHeaders(headers) }, void 0) }, void 0), _jsx("tbody", __assign({ className: "bg-white divide-y divide-gray-200" }, { children: renderTableRows() }), void 0)] }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
};
export default BulkUploadTable;
