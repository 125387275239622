var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AnnotationIcon, CogIcon, ExclamationIcon, LightningBoltIcon, ShoppingCartIcon, TableIcon, } from "@heroicons/react/outline";
var features = [
    { name: 'Domain Manager w/ Live Edit', icon: TableIcon, details: "View important domain name info at a glance and make updates on the fly." },
    { name: 'Expirations Dashboard', icon: ExclamationIcon, details: "Stay on top of important upcoming domain name expirations and renewal costs. Never let another key domain slip through the cracks." },
    { name: 'Bulk Import / Export', icon: LightningBoltIcon, details: "Export your domain list from popular domain platforms and import directly to DomainPage.io in seconds." },
    { name: 'Lead Management', icon: AnnotationIcon, details: "Keep track of all domain name inquiries and offers in one spot." },
    { name: 'Domain Marketplace', icon: ShoppingCartIcon, details: "List domains in our growing marketplace for maximum exposure - in 1 click!" },
    { name: 'Powerful Integrations', icon: CogIcon, details: "Leverage our growing list of integrations with Escrow.com, Google Analytics, and more." },
];
var FeatureBlocks = function () {
    return (_jsx("div", __assign({ className: "relative bg-white py-16 sm:py-24 lg:py-32" }, { children: _jsxs("div", __assign({ className: "mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl" }, { children: [_jsx("h2", __assign({ className: "text-base font-semibold tracking-wider text-indigo-600 uppercase" }, { children: "Sell More Domains" }), void 0), _jsx("p", __assign({ className: "mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl" }, { children: "Everything you need to manage your domain portfolio." }), void 0), _jsx("p", __assign({ className: "mt-5 max-w-prose mx-auto text-xl text-gray-500" }, { children: "Stay on top of the most important details of your domain portfolio, manage domain landing pages in a couple clicks, and sell more domains with powerful third-party integrations." }), void 0), _jsx("div", __assign({ className: "mt-12" }, { children: _jsx("div", __assign({ className: "grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" }, { children: features.map(function (feature) { return (_jsx("div", __assign({ className: "pt-6" }, { children: _jsx("div", __assign({ className: "flow-root bg-gray-50 rounded-lg px-6 pb-8" }, { children: _jsxs("div", __assign({ className: "-mt-6" }, { children: [_jsx("div", { children: _jsx("span", __assign({ className: "inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg" }, { children: _jsx(feature.icon, { className: "h-6 w-6 text-white", "aria-hidden": "true" }, void 0) }), void 0) }, void 0), _jsx("h3", __assign({ className: "mt-8 text-lg font-medium text-gray-900 tracking-tight" }, { children: feature.name }), void 0), _jsx("p", __assign({ className: "mt-5 text-base text-gray-500" }, { children: feature.details }), void 0)] }), void 0) }), void 0) }), feature.name)); }) }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default FeatureBlocks;
