export var Application;
(function (Application) {
    Application["URL"] = "";
    Application["FULL_URL"] = "";
    Application["FEEDBACK_URL"] = "https://sendpoint.io/id/domainpage";
    Application["RELEASE"] = "ALPHA 1.0";
    Application["MARKETPLACE_SUBDOMAIN"] = "shop";
    Application["WHOLESALE_MARKETPLACE_SUBDOMAIN"] = "wholesale";
})(Application || (Application = {}));
export var LOADING = "LOADING";
