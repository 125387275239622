var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import NotificationContext from "../../context/NotificationContext";
import { CheckCircleOutline, X, XCircleOutline } from "heroicons-react";
import { NOTIFICATION_HIDE } from "../../actions";
import { NotificationType } from "@domainpage/shared/src";
var Notification = function (_a) {
    var display = _a.display, title = _a.title, message = _a.message, type = _a.type;
    var _b = useContext(NotificationContext), notification = _b.state, dispatch = _b.dispatch;
    var handleHideToast = function () {
        dispatch({ type: NOTIFICATION_HIDE });
    };
    return (_jsx("div", __assign({ className: "".concat(display
            ? "z-50 fixed right-0 top-0 w-full flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
            : "hidden") }, { children: _jsx("div", __assign({ className: "".concat(display
                ? "transition ease-in duration-100 transition opacity-100 translate-y-0 opacity-100 sm:translate-x-0"
                : "transform ease-out duration-300 transition opacity-0 translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2", " max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto") }, { children: _jsx("div", __assign({ className: "rounded-lg shadow-xs overflow-hidden" }, { children: _jsx("div", __assign({ className: "p-4" }, { children: _jsxs("div", __assign({ className: "flex items-start" }, { children: [display ? (_jsx("div", __assign({ className: "flex-shrink-0" }, { children: notification.type === NotificationType.SUCCESS ? (_jsx(CheckCircleOutline, { className: "h-6 w-6 text-green-400" }, void 0)) : (_jsx(XCircleOutline, { className: "h-6 w-6 text-red-400" }, void 0)) }), void 0)) : null, _jsxs("div", __assign({ className: "ml-3 w-0 flex-1 pt-0.5" }, { children: [title ? _jsx("p", __assign({ className: "text-sm leading-5 font-medium text-gray-900" }, { children: title }), void 0) : null, _jsx("p", __assign({ className: "mt-1 text-sm leading-5 text-gray-500" }, { children: message }), void 0)] }), void 0), _jsx("div", __assign({ className: "ml-4 flex-shrink-0 flex" }, { children: _jsx("button", __assign({ onClick: function () { return handleHideToast(); }, className: "inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" }, { children: _jsx(X, { className: "h-5 w-5" }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0));
};
export default Notification;
