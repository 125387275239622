export var Marketplace;
(function (Marketplace) {
    Marketplace[Marketplace["PRICE_STEP"] = 100] = "PRICE_STEP";
    Marketplace[Marketplace["MIN_PRICE"] = 0] = "MIN_PRICE";
    Marketplace[Marketplace["MAX_PRICE"] = 10000] = "MAX_PRICE";
    Marketplace[Marketplace["STARTING_MIN_PRICE"] = 0] = "STARTING_MIN_PRICE";
    Marketplace[Marketplace["STARTING_MAX_PRICE"] = 10000] = "STARTING_MAX_PRICE";
    Marketplace[Marketplace["LENGTH_STEP"] = 1] = "LENGTH_STEP";
    Marketplace[Marketplace["MIN_LENGTH"] = 1] = "MIN_LENGTH";
    Marketplace[Marketplace["MAX_LENGTH"] = 50] = "MAX_LENGTH";
    Marketplace[Marketplace["STARTING_MIN_LENGTH"] = 1] = "STARTING_MIN_LENGTH";
    Marketplace[Marketplace["STARTING_MAX_LENGTH"] = 50] = "STARTING_MAX_LENGTH";
    Marketplace["LAYOUT_SLIM_CARD"] = "LAYOUT_SLIM_CARD";
    Marketplace["LAYOUT_CARD"] = "LAYOUT_CARD";
})(Marketplace || (Marketplace = {}));
