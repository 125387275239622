var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import { PageDirection } from "@domainpage/shared/src";
import { selectableRowColumnSchema } from "../../constants/table/selectableRowColumnSchema";
import { selectItemDeleteSection } from "../../constants/table/selectItemDeleteSection";
import Skeleton from "./../Skeleton";
import TablePagination from "./../TablePagination";
var GenericTable = function (_a) {
    var data = _a.data, _b = _a.handleUpdate, handleUpdate = _b === void 0 ? null : _b, _c = _a.selectItems, selectItems = _c === void 0 ? null : _c, _d = _a.selectableRowTableOptions, selectableRowTableOptions = _d === void 0 ? null : _d, initialState = _a.initialState, columnSchema = _a.columnSchema, paginateOptions = _a.paginateOptions, pageCallback = _a.pageCallback;
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(false), displayDeleteConfirmation = _f[0], setDisplayDeleteConfirmation = _f[1];
    var items = useMemo(function () { return loading ? Array(30).fill({}) : data; }, [loading, data]);
    var handlePageChange = function (direction) {
        if (direction == PageDirection.PREV) {
            if (paginateOptions.hasPrevPage) {
                pageCallback(paginateOptions.prevPage);
            }
        }
        if (direction === PageDirection.NEXT) {
            if (paginateOptions.hasNextPage) {
                pageCallback(paginateOptions.nextPage);
            }
        }
    };
    var columns = useMemo(function () { return loading ? columnSchema.map(function (column) { return (__assign(__assign({}, column), { Cell: _jsx("div", __assign({ className: "p-2" }, { children: _jsx(Skeleton, {}, void 0) }), void 0) })); }) : columnSchema; }, [loading]);
    var tableOptions = {
        columns: columns,
        data: data,
        initialState: initialState,
        manualPagination: true,
        autoResetPage: true,
        pageCount: paginateOptions.totalPages,
    };
    var hooksPlugin = function (hooks) { };
    if (selectableRowTableOptions) {
        var deleteCallback = selectableRowTableOptions.deleteCallback;
        tableOptions.selectItems = selectItems;
        tableOptions.deleteCallback = deleteCallback;
        hooksPlugin = function (hooks) { return hooks.visibleColumns.push(function (columns) { return __spreadArray([
            selectableRowColumnSchema
        ], columns, true); }); };
    }
    if (handleUpdate)
        tableOptions.handleUpdate = handleUpdate;
    var _g = useTable(tableOptions, usePagination, useRowSelect, hooksPlugin), getTableProps = _g.getTableProps, headerGroups = _g.headerGroups, rows = _g.rows, prepareRow = _g.prepareRow, allColumns = _g.allColumns, toggleHideColumn = _g.toggleHideColumn, toggleAllPageRowsSelected = _g.toggleAllPageRowsSelected, selectedFlatRows = _g.selectedFlatRows;
    useEffect(function () {
        setTimeout(function () {
            setLoading(false);
        }, 500);
    }, [items]);
    return (_jsxs("div", __assign({ className: "flex flex-col mt-2" }, { children: [selectableRowTableOptions ?
                _jsx("div", __assign({ className: "flex flex-wrap items-center space-x-4 sm:flex-1 sm:m-0 py-2" }, { children: selectItemDeleteSection({
                        displayDeleteConfirmation: displayDeleteConfirmation,
                        toggleAllPageRowsSelected: toggleAllPageRowsSelected,
                        setDisplayDeleteConfirmation: setDisplayDeleteConfirmation,
                        selectableRowTableOptions: selectableRowTableOptions,
                        selectedFlatRows: selectedFlatRows,
                        allColumns: allColumns,
                        toggleHideColumn: toggleHideColumn,
                    }) }), void 0) : null, _jsxs("div", __assign({ className: "align-middle shadow overflow-x-scroll" }, { children: [_jsx(TablePagination, { paginateOptions: paginateOptions, handlePageChange: handlePageChange, topSection: true }, void 0), _jsxs("table", __assign({}, getTableProps(function (table) { return ({
                        className: "min-w-full divide-y divide-gray-200",
                    }); }), { children: [_jsx("thead", { children: headerGroups.map(function (headerGroup, key) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(function (tr) {
                                    return ({
                                        key: key,
                                    });
                                }), { children: headerGroup.headers.map(function (column) { return (_jsx("th", __assign({}, column.getHeaderProps(function (column) {
                                        return ({
                                            key: column.key,
                                            className: "text-center px-2 py-4 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 bg-gray-50 overflow-hidden",
                                        });
                                    }), { children: column.render("Header") }), void 0)); }) }), void 0)); }) }, void 0), _jsx("tbody", { children: rows.map(function (row) {
                                    prepareRow(row);
                                    return (_jsx("tr", __assign({}, row.getRowProps(function (row, meta) {
                                        return ({
                                            key: "".concat(meta.row.original.name, "-").concat(meta.row.id),
                                            className: "group",
                                        });
                                    }), { children: row.cells.map(function (cell, key) {
                                            return (_jsx("td", __assign({}, cell.getCellProps(function (cell, meta) {
                                                return ({
                                                    className: "px-4 py-3 relative bg-white border border-gray-200 group-hover:bg-gray-50 whitespace-nowrap text-sm text-gray-700",
                                                });
                                            }), { children: cell.render("Cell") }), void 0));
                                        }) }), void 0));
                                }) }, void 0)] }), void 0), _jsx(TablePagination, { paginateOptions: paginateOptions, handlePageChange: handlePageChange, topSection: false }, void 0)] }), void 0)] }), void 0));
};
export default GenericTable;
