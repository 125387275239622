import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ArrowSmDownIcon, ArrowSmUpIcon, CalendarIcon } from "@heroicons/react/outline";
import { ClockOutline, EyeOutline, HashtagOutline, LightningBoltOutline } from "heroicons-react";
import { Sort } from "../sort";
export var domainTableSortBar = [
    {
        name: "Latest",
        value: Sort.LATEST,
        current: true,
        icon: _jsx(ClockOutline, { className: "h-4 w-4 mr-1" }, void 0)
    },
    {
        name: "Most Views",
        value: Sort.MOST_VIEWS,
        current: false,
        icon: _jsx(EyeOutline, { className: "h-4 w-4 mr-1" }, void 0)
    },
    {
        name: "Alphabetical",
        value: Sort.ALPHABETICAL,
        current: false,
        icon: _jsx(HashtagOutline, { className: "h-4 w-4 mr-1" }, void 0)
    },
    {
        name: "Expiration",
        value: Sort.EXPIRATION,
        current: false,
        icon: _jsx(CalendarIcon, { className: "h-4 w-4 mr-1" }, void 0)
    },
    {
        name: "Buy It Now",
        value: Sort.BUY_IT_NOW,
        current: false,
        icon: _jsx(LightningBoltOutline, { className: "h-4 w-4 mr-1" }, void 0),
    },
    {
        name: "Highest Price",
        value: Sort.HIGHEST_PRICE,
        current: false,
        icon: _jsx(ArrowSmUpIcon, { className: "h-4 w-4 mr-1" }, void 0),
    },
    {
        name: "Lowest Price",
        value: Sort.LOWEST_PRICE,
        current: false,
        icon: _jsx(ArrowSmDownIcon, { className: "h-4 w-4 mr-1" }, void 0),
    },
];
