var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import IndeterminateCheckbox from "../../components/table/IndeterminateCheckbox";
import { Header } from "@domainpage/shared/src/constants/headers";
export var selectableRowColumnSchema = {
    id: Header.TOGGLE_BUTTON,
    Header: function (_a) {
        var getToggleAllRowsSelectedProps = _a.getToggleAllRowsSelectedProps;
        return (_jsx("div", { children: _jsx(IndeterminateCheckbox, __assign({}, getToggleAllRowsSelectedProps()), void 0) }, void 0));
    },
    Cell: function (_a) {
        var row = _a.row;
        return (_jsx("div", { children: _jsx(IndeterminateCheckbox, __assign({}, row.getToggleRowSelectedProps()), void 0) }, void 0));
    },
    totalWidth: 5,
};
