var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var CardSkelton = function () {
    return (_jsxs("div", __assign({ className: "animate-pulse bg-white h-full shadow-md hover:shadow-sm transition:shadow duration-500 ease-in-out border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200" }, { children: [_jsxs("div", __assign({ className: "py-4 px-6 max-h-full h-60 flex flex-col" }, { children: [_jsx("div", { className: "mt-2 bg-gray-200 w-3/4 h-8 rounded-md" }, void 0), _jsx("div", { className: 'mt-2 bg-gray-200 w-1/4 h-4 rounded-md' }, void 0), _jsx("div", { className: 'mt-2 bg-gray-200 w-2/4 h-4 rounded-md' }, void 0), _jsx("div", { className: 'flex-grow' }, void 0), _jsx("div", { className: "bg-gray-200 h-10 w-full rounded-md" }, void 0)] }), void 0), _jsx("div", __assign({ className: 'py-4 px-6 ' }, { children: _jsx("div", { className: "bg-gray-200 h-4 w-full rounded-md" }, void 0) }), void 0)] }), void 0));
};
export default CardSkelton;
