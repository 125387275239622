var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ChevronLeftOutline, ChevronRightOutline } from "heroicons-react";
import React from "react";
import { PageDirection } from "@domainpage/shared/src";
var TablePagination = function (_a) {
    var paginateOptions = _a.paginateOptions, handlePageChange = _a.handlePageChange, _b = _a.topSection, topSection = _b === void 0 ? true : _b;
    return (_jsxs("nav", __assign({ className: "".concat(topSection ? "border-b rounded-t-md" : "border-t rounded-b-md", " overflow-x-auto bg-white px-4 py-2 flex items-center justify-between shadow border-gray-200 sm:px-6"), "aria-label": "Pagination" }, { children: [_jsx("div", __assign({ className: "hidden sm:block" }, { children: _jsxs("p", __assign({ className: "text-sm text-gray-700" }, { children: ["Showing", _jsxs("span", __assign({ className: "font-medium" }, { children: [" ", (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs) ? ((paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.limit) * ((paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page) - 1) + 1) : 0, " "] }), void 0), "to", _jsxs("span", __assign({ className: "font-medium" }, { children: [" ", (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page) * (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.limit) > (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs)
                                    ? (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs) || 0
                                    : ((paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page) * (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.limit)) || 0, " "] }), void 0), "of ", _jsxs("span", __assign({ className: "font-medium" }, { children: [" ", (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs) || 0, " "] }), void 0), "results"] }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex-1 flex justify-between sm:justify-end" }, { children: [_jsx("button", __assign({ onClick: function () { return handlePageChange(PageDirection.PREV); }, className: "".concat(!(paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.hasPrevPage) ? "disabled cursor-not-allowed	 opacity-50" : "", " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50") }, { children: _jsx(ChevronLeftOutline, { className: "h-4 w-4" }, void 0) }), void 0), _jsx("div", __assign({ className: "sm:hidden" }, { children: _jsxs("p", __assign({ className: "text-sm text-gray-700 align-middle inline-block" }, { children: [_jsxs("span", __assign({ className: "font-medium" }, { children: [" ", (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs) ? ((paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.limit) * ((paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page) - 1) + 1) : 0, " "] }), void 0), "to", _jsxs("span", __assign({ className: "font-medium" }, { children: [" ", (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page) * (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.limit) > (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs)
                                            ? (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs) || 0
                                            : (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page) * (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.limit) || 0, " "] }), void 0), "of ", _jsxs("span", __assign({ className: "font-medium" }, { children: [" ", (paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.totalDocs) || 0, " "] }), void 0)] }), void 0) }), void 0), _jsx("button", __assign({ onClick: function () { return handlePageChange(PageDirection.NEXT); }, className: "".concat(!(paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.hasNextPage) ? "disabled cursor-not-allowed	 opacity-50" : "", " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50") }, { children: _jsx(ChevronRightOutline, { className: "h-4 w-4" }, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default TablePagination;
