var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var AppLink = function (_a) {
    var path = _a.to, _b = _a.className, className = _b === void 0 ? null : _b, children = _a.children, _c = _a.onClick, onClick = _c === void 0 ? null : _c, _d = _a.key, key = _d === void 0 ? null : _d, _e = _a.customDomain, customDomain = _e === void 0 ? null : _e, _f = _a.subdomain, subdomain = _f === void 0 ? null : _f, _g = _a.newTab, newTab = _g === void 0 ? false : _g;
    if (customDomain) {
        return (_jsx("a", __assign({ onClick: onClick, className: className, target: newTab ? "_blank" : "_self", href: "https://".concat(customDomain).concat(path) }, { children: children }), key));
    }
    else if (subdomain) {
        return (_jsx("a", __assign({ onClick: onClick, className: className, target: newTab ? "_blank" : "_self", href: "https://".concat(subdomain, ".").concat(process.env.REACT_APP_ROOT_URL).concat(path) }, { children: children }), key));
    }
    else {
        return (_jsx("a", __assign({ onClick: onClick, className: className, target: newTab ? "_blank" : "_self", href: "https://".concat(process.env.REACT_APP_ROOT_URL).concat(path) }, { children: children }), key));
    }
};
export default AppLink;
