var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React from "react";
import { PageDirection } from "@domainpage/shared/src";
var PageArrows = function (options) {
    var handlePageChange = function (direction) {
        if (direction == PageDirection.PREV) {
            if (options.hasPrevPage) {
                options.callback(options.prevPage);
            }
        }
        if (direction === PageDirection.NEXT) {
            if (options.hasNextPage) {
                options.callback(options.nextPage);
            }
        }
    };
    var fromAmount = ((options.page - 1) * options.limit) + 1 || 0;
    var toAmount = (options.page * options.limit > options.totalDocs) ? options.totalDocs : options.page * options.limit;
    return (_jsxs("div", __assign({ className: 'bg-gray-50 flex justify-center sm:justify-end m-1 sm:m-0' }, { children: [_jsxs("p", __assign({ className: 'my-2 mr-4 text-xs text-right' }, { children: [fromAmount, " - ", toAmount, " of ", options.totalDocs, " results"] }), void 0), _jsxs("span", __assign({ className: "relative z-0 inline-flex shadow-sm rounded-md" }, { children: [_jsxs("button", __assign({ onClick: function () { return handlePageChange(PageDirection.PREV); }, type: "button", className: "".concat(!options.hasPrevPage ? "opacity-50 cursor-not-allowed" : null, " relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500") }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Previous" }), void 0), _jsx(ChevronLeftIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0), _jsxs("button", __assign({ onClick: function () { return handlePageChange(PageDirection.NEXT); }, type: "button", className: "".concat(!options.hasNextPage ? "opacity-50 cursor-not-allowed" : null, " -ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500") }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Next" }), void 0), _jsx(ChevronRightIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default PageArrows;
