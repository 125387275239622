export var MenuItem;
(function (MenuItem) {
    MenuItem["FEATURES"] = "Features";
    MenuItem["PRICING"] = "Pricing";
    MenuItem["DASHBOARD"] = "Dashboard";
    MenuItem["INBOX"] = "Inbox";
    MenuItem["HELP"] = "Help";
    MenuItem["DOCS"] = "Docs";
    MenuItem["LEADS"] = "Leads";
    MenuItem["SALES"] = "Sales";
    MenuItem["EXPIRATIONS"] = "Expirations";
    MenuItem["DOMAINS"] = "Domains";
    MenuItem["ADD_DOMAIN"] = "Add Domain";
    MenuItem["BULK_IMPORT_EXPORT"] = "Bulk Import / Export";
    MenuItem["ACCOUNT"] = "Account";
    MenuItem["BILLING"] = "Billing";
    MenuItem["LOGOUT"] = "Logout";
    MenuItem["MARKETPLACE"] = "Marketplace";
    MenuItem["UPGRADE_ACCOUNT"] = "Upgrade Account";
    MenuItem["SUPPORT"] = "Support";
})(MenuItem || (MenuItem = {}));
export var RedirectSubdomain;
(function (RedirectSubdomain) {
    RedirectSubdomain["MARKETPLACE"] = "shop";
})(RedirectSubdomain || (RedirectSubdomain = {}));
export var Redirect;
(function (Redirect) {
    Redirect["ROOT"] = "/";
    Redirect["FEATURES"] = "/features";
    Redirect["PRICING"] = "/pricing";
    Redirect["DASHBOARD"] = "/dashboard";
    Redirect["EDIT"] = "/edit";
    Redirect["LEADS"] = "/dashboard/leads";
    Redirect["SALES"] = "/dashboard/sales";
    Redirect["EXPIRATIONS"] = "/dashboard/expirations";
    Redirect["HOME_DOCS"] = "/docs";
    Redirect["INBOX"] = "/dashboard/inbox";
    Redirect["FORMS"] = "/dashboard/forms";
    Redirect["DOMAINS"] = "/dashboard/domains";
    Redirect["DOMAINS_NEW"] = "/dashboard/domains/new";
    Redirect["BULK_IMPORT_EXPORT"] = "/dashboard/domains/bulk";
    Redirect["DOCS"] = "/dashboard/docs";
    Redirect["PROFILE"] = "/dashboard/profile";
    Redirect["ACCOUNT"] = "/dashboard/account";
    Redirect["BILLING"] = "/dashboard/billing";
    Redirect["HELP"] = "/dashboard/help";
    Redirect["LOGIN"] = "/login";
    Redirect["LOGOUT"] = "/api/user/logout";
    Redirect["REGISTER"] = "/register";
    Redirect["CONFIRM_EMAIL"] = "/confirm-email";
    Redirect["CONFIRM_DEVICE"] = "/confirm-device";
    Redirect["SUPPORT"] = "/dashboard/support";
    Redirect["NOT_FOUND"] = "/not-found";
})(Redirect || (Redirect = {}));
export var Menu = [
    { title: MenuItem.FEATURES, link: Redirect.FEATURES, active: true },
    { title: MenuItem.PRICING, link: Redirect.PRICING, active: false },
    { title: MenuItem.MARKETPLACE, link: Redirect.ROOT, subdomain: RedirectSubdomain.MARKETPLACE, active: false },
];
export var DashboardDropdownMenu = [
    { title: MenuItem.MARKETPLACE, link: Redirect.ROOT, subdomain: RedirectSubdomain.MARKETPLACE, active: true },
    { title: MenuItem.ACCOUNT, link: Redirect.PROFILE, active: false },
    { title: MenuItem.BILLING, link: Redirect.BILLING, active: false },
    { title: MenuItem.LOGOUT, link: "/api/user/logout", active: false },
];
export var AuthenticatedDropdownMenu = [
    { title: MenuItem.DASHBOARD, link: Redirect.DASHBOARD, active: true },
    { title: MenuItem.MARKETPLACE, link: Redirect.ROOT, subdomain: RedirectSubdomain.MARKETPLACE, active: false },
    { title: MenuItem.ACCOUNT, link: Redirect.ACCOUNT, active: false },
    { title: MenuItem.BILLING, link: Redirect.BILLING, active: false },
    { title: MenuItem.HELP, link: Redirect.HELP, active: false },
    { title: MenuItem.LOGOUT, link: "/api/user/logout", active: false },
];
export var AuthenticatedMobileDropdownMenu = [
    { title: MenuItem.DASHBOARD, link: Redirect.DASHBOARD, active: true },
    { title: MenuItem.MARKETPLACE, link: Redirect.ROOT, subdomain: RedirectSubdomain.MARKETPLACE, active: false },
    { title: MenuItem.ACCOUNT, link: Redirect.ACCOUNT, active: false },
    { title: MenuItem.BILLING, link: Redirect.BILLING, active: false },
];
export var AuthenticatedMobileDropdownSubMenu = [
    { title: MenuItem.HELP, link: Redirect.HELP, active: false },
    { title: MenuItem.LOGOUT, link: "/api/user/logout", active: false },
];
