var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DomainDetails from "../../components/DomainDetails";
import NotificationContext from "../../context/NotificationContext";
import { LOADING } from "../../actions";
import { emitToastNotification } from "../../utils";
import DashboardContext from "../../context/DashboardContext";
import { ExternalLinkOutline } from "heroicons-react";
import AuthContext from "../../context/AuthContext";
var DomainEdit = function () {
    var _a = useContext(NotificationContext), notification = _a.state, dispatch = _a.dispatch;
    var _b = useContext(DashboardContext), dashboard = _b.state, dashboardDispatch = _b.dispatch;
    var auth = useContext(AuthContext).state;
    var params = useParams();
    var domainName = params.domain;
    var _c = useState(null), data = _c[0], setData = _c[1];
    var fetchDomainDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, notification_1, domainObj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/domains/".concat(domainName))];
                case 1:
                    res = _a.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, res.json()];
                case 2:
                    notification_1 = _a.sent();
                    return [2 /*return*/, emitToastNotification(dispatch, notification_1)];
                case 3: return [4 /*yield*/, res.json()];
                case 4:
                    domainObj = _a.sent();
                    setData(domainObj);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleDomainUpdate = function (domainObj) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = { domain: domainObj };
                    return [4 /*yield*/, fetch("/api/domains/edit", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(payload),
                        })];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    emitToastNotification(dispatch, data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        dashboardDispatch({ type: LOADING, payload: true });
        fetchDomainDetails().catch(function (err) { return console.log(err); });
        dashboardDispatch({ type: LOADING, payload: false });
    }, [domainName]);
    return data && auth ? (_jsx(Fragment, { children: _jsx("div", __assign({ className: "mt-8" }, { children: _jsx("div", __assign({ className: "max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" }, { children: _jsxs("div", __assign({ className: "space-y-6" }, { children: [_jsx("h2", __assign({ className: "text-lg leading-6 font-medium text-gray-900" }, { children: "Edit Domain" }), void 0), _jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx("a", __assign({ target: "_blank", href: "https://".concat(auth.user.username, ".").concat(process.env.REACT_APP_ROOT_URL, "/").concat(domainName) }, { children: _jsxs("button", __assign({ type: "submit", className: "my-2 sm:my-0 inline-flex items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: [_jsx(ExternalLinkOutline, { className: "h-4 w-4 mr-1" }, void 0), "View Domain Page"] }), void 0) }), void 0) }), void 0), _jsx(DomainDetails, { edit: true, data: data.payload, callback: handleDomainUpdate }, void 0)] }), void 0) }), void 0) }), void 0) }, void 0)) : null;
};
export default DomainEdit;
