var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var renderLogos = function (_a) {
    var logos = _a.logos;
    return logos.map(function (logo) {
        return (_jsx("div", __assign({ className: "col-span-1 flex justify-center md:col-span-2 lg:col-span-1" }, { children: _jsx("img", { className: "h-16", src: logo.src, alt: logo.title }, void 0) }), void 0));
    });
};
var LogoCloud = function (_a) {
    var logos = _a.logos, _b = _a.gridColsLg, gridColsLg = _b === void 0 ? 4 : _b, _c = _a.gridColsMd, gridColsMd = _c === void 0 ? 6 : _c, _d = _a.gridCols, gridCols = _d === void 0 ? 2 : _d;
    return (_jsx("div", __assign({ className: "" }, { children: _jsx("div", __assign({ className: "max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8" }, { children: _jsx("div", __assign({ className: "grid grid-cols-".concat(gridCols, " gap-4 md:grid-cols-").concat(gridColsMd, " lg:grid-cols-").concat(gridColsLg) }, { children: renderLogos(logos) }), void 0) }), void 0) }), void 0));
};
export default LogoCloud;
