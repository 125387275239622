var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useContext } from "react";
import { Check } from "heroicons-react";
import Tag from "./Tag";
import { loadStripe } from "@stripe/stripe-js";
import NotificationContext from "../context/NotificationContext";
import { emitToastNotification } from "../utils";
import { Plan, Interval, NotificationType, Redirect } from "@domainpage/shared/src";
var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
var PricingCard = function (_a) {
    var title = _a.title, description = _a.description, _b = _a.buttonText, buttonText = _b === void 0 ? "Get Started" : _b, price = _a.price, _c = _a.plan, plan = _c === void 0 ? Plan.PLAN_ONE : _c, _d = _a.interval, interval = _d === void 0 ? Interval.MONTHLY : _d, featuresArr = _a.featuresArr, _e = _a.highlighted, highlighted = _e === void 0 ? false : _e, _f = _a.buttonColor, buttonColor = _f === void 0 ? "gray" : _f, annualSavingsText = _a.annualSavingsText, _g = _a.buttonDisabled, buttonDisabled = _g === void 0 ? false : _g, _h = _a.hideButton, hideButton = _h === void 0 ? false : _h, _j = _a.callback, callback = _j === void 0 ? null : _j;
    var notificationDispatch = useContext(NotificationContext).dispatch;
    var renderFeatures = function (features) {
        return features.map(function (feature, key) {
            return (_jsxs("li", __assign({ className: "flex space-x-3" }, { children: [_jsx(Check, { className: "flex-shrink-0 h-5 w-5 text-green-500" }, void 0), _jsx("span", __assign({ className: "text-sm text-gray-500" }, { children: feature.description }), void 0)] }), key));
        });
    };
    var handleSendToRegisterPage = function () {
        window.location.href = Redirect.REGISTER;
    };
    var handleStripeCheckoutSession = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stripe, payload, res, session, checkout, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (callback != null) {
                        callback(true);
                    }
                    return [4 /*yield*/, stripePromise];
                case 1:
                    stripe = _a.sent();
                    payload = {
                        plan: plan,
                        interval: interval,
                    };
                    return [4 /*yield*/, fetch("/api/payments/checkout-session", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(payload),
                        })];
                case 2:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 3:
                    session = _a.sent();
                    return [4 /*yield*/, stripe.redirectToCheckout({
                            sessionId: session.id,
                        })];
                case 4:
                    checkout = _a.sent();
                    if (checkout.error) {
                        console.log("Stripe Checkout Error:", checkout.error);
                        notification = {
                            title: "Stripe Checkout Error",
                            message: "Please contact support if this issue persists",
                            type: NotificationType.ERROR,
                        };
                        emitToastNotification(notificationDispatch, notification);
                    }
                    if (callback != null)
                        callback(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: "".concat(highlighted ? "border-4 border-indigo-600" : "border border-gray-200", " bg-white rounded-lg shadow-md divide-y divide-gray-200") }, { children: [_jsxs("div", __assign({ className: "p-6" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("h2", __assign({ className: "text-lg leading-6 font-medium text-gray-900" }, { children: title }), void 0), annualSavingsText && interval === Interval.YEARLY ? (_jsx(Tag, { color: "indigo", text: annualSavingsText }, void 0)) : null] }), void 0), _jsx("p", __assign({ className: "mt-4 text-sm text-gray-500" }, { children: description }), void 0), _jsxs("p", __assign({ className: "mt-8" }, { children: [_jsxs("span", __assign({ className: "text-4xl font-extrabold text-gray-900" }, { children: ["$", price] }), void 0), _jsx("span", __assign({ className: "text-base font-medium text-gray-500" }, { children: interval === Interval.MONTHLY
                                        ? " /" + Interval.MONTHLY.toLowerCase()
                                        : " /" + Interval.YEARLY.toLowerCase() }), void 0)] }), void 0), !hideButton ? (_jsx("a", __assign({ onClick: !callback ? function () { return handleSendToRegisterPage(); } : function () { return handleStripeCheckoutSession(); }, className: "".concat(buttonDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer", " mt-8 block w-full bg-").concat(buttonColor, "-600 border border-").concat(buttonColor, "-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900") }, { children: buttonText }), void 0)) : null] }), void 0), _jsxs("div", __assign({ className: "pt-6 pb-8 px-6" }, { children: [_jsx("h3", __assign({ className: "text-xs font-medium text-gray-900 tracking-wide uppercase" }, { children: "What's included" }), void 0), _jsx("ul", __assign({ className: "mt-6 space-y-4" }, { children: renderFeatures(featuresArr) }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default PricingCard;
