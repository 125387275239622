export var Plan;
(function (Plan) {
    Plan["PLAN_ONE"] = "FREE";
    Plan["PLAN_ONE_DISPLAY"] = "Free";
    Plan["PLAN_ONE_DESCRIPTION"] = "All the essentials to create & promote your domain portfolio";
    Plan[Plan["PLAN_ONE_MONTHLY_PRICE"] = 0] = "PLAN_ONE_MONTHLY_PRICE";
    Plan[Plan["PLAN_ONE_ANNUAL_PRICE"] = 0] = "PLAN_ONE_ANNUAL_PRICE";
    Plan[Plan["PLAN_ONE_DOMAIN_LIMIT"] = 100] = "PLAN_ONE_DOMAIN_LIMIT";
    Plan[Plan["PLAN_ONE_LEAD_CAPTURE_LIMIT"] = 50] = "PLAN_ONE_LEAD_CAPTURE_LIMIT";
    Plan["PLAN_TWO"] = "PRO";
    Plan["PLAN_TWO_DISPLAY"] = "Pro";
    Plan["PLAN_TWO_DESCRIPTION"] = "Advanced domain investor tools to take your business to the next level";
    Plan[Plan["PLAN_TWO_MONTHLY_PRICE"] = 9] = "PLAN_TWO_MONTHLY_PRICE";
    Plan[Plan["PLAN_TWO_ANNUAL_PRICE"] = 99] = "PLAN_TWO_ANNUAL_PRICE";
    Plan[Plan["PLAN_TWO_DOMAIN_LIMIT"] = 5000] = "PLAN_TWO_DOMAIN_LIMIT";
    Plan[Plan["PLAN_TWO_LEAD_CAPTURE_LIMIT"] = 2500] = "PLAN_TWO_LEAD_CAPTURE_LIMIT";
})(Plan || (Plan = {}));
