var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
var Slider = function (_a) {
    var min = _a.min, max = _a.max, minVal = _a.minVal, maxVal = _a.maxVal, callback = _a.callback, step = _a.step;
    var handleUpdate = function (range) {
        callback(range);
    };
    var wrapperStyle = { width: "100%" };
    var trackStyle = { backgroundColor: '#5850ec' };
    var handleStyle = { borderColor: '#5850ec' };
    return (_jsx("div", __assign({ style: wrapperStyle }, { children: _jsx(Range, { handleStyle: [handleStyle, handleStyle], trackStyle: [trackStyle], allowCross: false, step: step, min: min, max: max, value: [minVal, maxVal], onChange: handleUpdate }, void 0) }), void 0));
};
export default Slider;
