var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CurrencyDollarOutline, ExclamationOutline, ShieldCheckOutline } from "heroicons-react";
import React, { useContext, useEffect, useState } from "react";
import PillGroup from "../../components/common/PillGroup";
import Stats from "../../components/Stats";
import GenericTable from "../../components/table/GenericTable";
import { expirationsTableColumnSchema } from "../../constants/table/expirationsTableColumnSchema";
import AuthContext from "../../context/AuthContext";
import DashboardContext from "../../context/DashboardContext";
import NotificationContext from "../../context/NotificationContext";
import { Interval, Paginate } from "@domainpage/shared/src";
import { emitToastNotification, mapIntervalToIntervalDisplay } from "../../utils";
var DashboardExpirations = function () {
    var _a = useContext(NotificationContext), notification = _a.state, dispatch = _a.dispatch;
    var _b = useContext(DashboardContext), dashboard = _b.state, dashboardDispatch = _b.dispatch;
    var auth = useContext(AuthContext).state;
    var _c = useState([]), expirations = _c[0], setExpirations = _c[1];
    var _d = useState(Interval.MONTH), interval = _d[0], setInterval = _d[1];
    var _e = useState({}), paginateOptions = _e[0], setPaginateOptions = _e[1];
    var _f = useState(Paginate.PAGE), page = _f[0], setPage = _f[1];
    var _g = useState(Paginate.LIMIT), limit = _g[0], setLimit = _g[1];
    var _h = useState({}), stats = _h[0], setStats = _h[1];
    var _j = useState(0), totalRenewalCost = _j[0], setTotalRenewalCost = _j[1];
    var _k = useState(0), totalRegistrars = _k[0], setTotalRegistrars = _k[1];
    var fetchExpiringDomains = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, notification_1, _a, payload, totalRenewalCostThisInterval, totalRegistrarsThisInterval;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch("/api/domains/expiring?interval=".concat(interval, "&page=").concat(page, "&limit=").concat(limit))];
                case 1:
                    res = _b.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, res.json()];
                case 2:
                    notification_1 = _b.sent();
                    return [2 /*return*/, emitToastNotification(dispatch, notification_1)];
                case 3: return [4 /*yield*/, res.json()];
                case 4:
                    _a = _b.sent(), payload = _a.payload, totalRenewalCostThisInterval = _a.totalRenewalCostThisInterval, totalRegistrarsThisInterval = _a.totalRegistrarsThisInterval;
                    setPaginateOptions(payload);
                    setExpirations(payload.docs);
                    setTotalRenewalCost(totalRenewalCostThisInterval);
                    setTotalRegistrars(totalRegistrarsThisInterval);
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var pillGroupItems = [
        { name: Interval.DAY_DISPLAY, value: Interval.DAY, current: false },
        { name: Interval.WEEK_DISPLAY, value: Interval.WEEK, current: false },
        { name: Interval.MONTH_DISPLAY, value: Interval.MONTH, current: true },
        { name: Interval.QUARTER_DISPLAY, value: Interval.QUARTER, current: false },
        { name: Interval.YEAR_DISPLAY, value: Interval.YEAR, current: false },
    ];
    var _l = useState(pillGroupItems), items = _l[0], setItems = _l[1];
    var fetchDomainPortfolioStats = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/domains/portfolio-stats?interval=".concat(interval))];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    setStats(data.payload);
                    return [2 /*return*/];
            }
        });
    }); };
    var initialState = {
        hiddenColumns: [],
        pageIndex: 0,
        pageSize: 0,
    };
    var intervalDisplay = mapIntervalToIntervalDisplay(interval);
    var statsData = [
        { name: "Expirations this ".concat(intervalDisplay), stat: paginateOptions.totalDocs, icon: _jsx(ExclamationOutline, { className: "h-6 w-6 text-gray-400" }, void 0) },
        { name: "Renewal Cost this ".concat(intervalDisplay), stat: "$".concat(totalRenewalCost), icon: _jsx(CurrencyDollarOutline, { className: "h-6 w-6 text-gray-400" }, void 0) },
        { name: "# of Registrars", stat: totalRegistrars, icon: _jsx(ShieldCheckOutline, { className: "h-6 w-6 text-gray-400" }, void 0) },
    ];
    useEffect(function () {
        fetchExpiringDomains().catch(function (err) { return err; });
        fetchDomainPortfolioStats().catch(function (err) { return err; });
    }, [interval, page]);
    useEffect(function () {
        setPage(Paginate.PAGE);
    }, [interval]);
    useEffect(function () { }, [expirations]);
    return expirations ? (_jsx("div", __assign({ className: "mt-8" }, { children: _jsx("div", __assign({ className: "max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" }, { children: _jsxs("div", __assign({ className: "space-y-6" }, { children: [_jsx("h2", __assign({ className: "text-lg leading-6 font-medium text-gray-900" }, { children: "Expirations This ".concat(intervalDisplay) }), void 0), _jsx("div", __assign({ className: "my-2" }, { children: _jsx(PillGroup, { items: items, itemsCallback: setItems, selectedCallback: setInterval }, void 0) }), void 0), _jsx(Stats, { data: statsData }, void 0), _jsx("div", __assign({ className: "flex flex-col" }, { children: _jsx("div", __assign({ className: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }, { children: _jsx("div", __assign({ className: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" }, { children: _jsx(GenericTable, { data: expirations, initialState: initialState, columnSchema: expirationsTableColumnSchema, paginateOptions: paginateOptions, pageCallback: setPage }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)) : null;
};
export default DashboardExpirations;
