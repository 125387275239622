export var Registrar;
(function (Registrar) {
    Registrar["BLUEHOST"] = "bluehost";
    Registrar["BLUEHOST_DISPLAY"] = "Bluehost";
    Registrar["DOMAIN_COM"] = "domain_com";
    Registrar["DOMAIN_COM_DISPLAY"] = "Domain.com";
    Registrar["DYNADOT"] = "dynadot";
    Registrar["DYNADOT_DISPLAY"] = "Dynadot";
    Registrar["ENOM"] = "enom";
    Registrar["ENOM_DISPLAY"] = "Enom";
    Registrar["EPIK"] = "epik";
    Registrar["EPIK_DISPLAY"] = "Epik";
    Registrar["GODADDY"] = "godaddy";
    Registrar["GODADDY_DISPLAY"] = "GoDaddy";
    Registrar["GOOGLE_DOMAINS"] = "google_domains";
    Registrar["GOOGLE_DOMAINS_DISPLAY"] = "Google Domains";
    Registrar["HOSTGATOR"] = "hostgator";
    Registrar["HOSTGATOR_DISPLAY"] = "HostGator";
    Registrar["HOVER"] = "hover";
    Registrar["HOVER_DISPLAY"] = "Hover";
    Registrar["NAME_COM"] = "name_com";
    Registrar["NAME_COM_DISPLAY"] = "Name.com";
    Registrar["NAMECHEAP"] = "namecheap";
    Registrar["NAMECHEAP_DISPLAY"] = "NameCheap";
    Registrar["NAME_SILO"] = "name_silo";
    Registrar["NAME_SILO_DISPLAY"] = "NameSilo";
    Registrar["NETWORK_SOLUTIONS"] = "network_solutions";
    Registrar["NETWORK_SOLUTIONS_DISPLAY"] = "Network Solutions";
    Registrar["ONEHUNDREDONE_DOMAIN"] = "101_domain";
    Registrar["ONEHUNDREDONE_DOMAIN_DISPLAY"] = "101Domain.com";
    Registrar["PORKBUN"] = "porkbun";
    Registrar["PORKBUN_DISPLAY"] = "Porkbun";
    Registrar["REGISTER_COM"] = "register_com";
    Registrar["REGISTER_COM_DISPLAY"] = "Register.com";
    Registrar["SAV"] = "sav";
    Registrar["SAV_DISPLAY"] = "Sav.com";
    Registrar["SITEGROUND"] = "siteground";
    Registrar["SITEGROUND_DISPLAY"] = "SiteGround";
    Registrar["UNSTOPPABLE_DOMAINS"] = "unstoppable_domains";
    Registrar["UNSTOPPABLE_DOMAINS_DISPLAY"] = "Unstoppable Domains";
    Registrar["OTHER"] = "other";
    Registrar["OTHER_DISPLAY"] = "Other";
})(Registrar || (Registrar = {}));
export var Registrars = [
    { name: "--", display: "--" },
    { name: Registrar.BLUEHOST, display: Registrar.BLUEHOST_DISPLAY },
    { name: Registrar.DOMAIN_COM, display: Registrar.DOMAIN_COM_DISPLAY },
    { name: Registrar.DYNADOT, display: Registrar.DYNADOT_DISPLAY },
    { name: Registrar.ENOM, display: Registrar.ENOM_DISPLAY },
    { name: Registrar.EPIK, display: Registrar.EPIK_DISPLAY },
    { name: Registrar.GODADDY, display: Registrar.GODADDY_DISPLAY },
    { name: Registrar.GOOGLE_DOMAINS, display: Registrar.GOOGLE_DOMAINS_DISPLAY },
    { name: Registrar.HOSTGATOR, display: Registrar.HOSTGATOR_DISPLAY },
    { name: Registrar.HOVER, display: Registrar.HOVER_DISPLAY },
    { name: Registrar.NAME_COM, display: Registrar.NAME_COM_DISPLAY },
    { name: Registrar.NAMECHEAP, display: Registrar.NAMECHEAP_DISPLAY },
    { name: Registrar.NAME_SILO, display: Registrar.NAME_SILO_DISPLAY },
    { name: Registrar.NETWORK_SOLUTIONS, display: Registrar.NETWORK_SOLUTIONS_DISPLAY },
    { name: Registrar.ONEHUNDREDONE_DOMAIN, display: Registrar.ONEHUNDREDONE_DOMAIN_DISPLAY },
    { name: Registrar.PORKBUN, display: Registrar.PORKBUN_DISPLAY },
    { name: Registrar.REGISTER_COM, display: Registrar.REGISTER_COM_DISPLAY },
    { name: Registrar.SAV, display: Registrar.SAV_DISPLAY },
    { name: Registrar.SITEGROUND, display: Registrar.SITEGROUND_DISPLAY },
    { name: Registrar.UNSTOPPABLE_DOMAINS, display: Registrar.UNSTOPPABLE_DOMAINS_DISPLAY },
    { name: Registrar.OTHER, display: Registrar.OTHER_DISPLAY },
];
