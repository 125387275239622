var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
var RegisterCTA = function () {
    return (_jsx("div", __assign({ className: "bg-white" }, { children: _jsxs("div", __assign({ className: "max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8" }, { children: [_jsxs("h2", __assign({ className: "text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl" }, { children: [_jsx("span", __assign({ className: "block" }, { children: "Ready to sell more domains?" }), void 0), _jsx("span", __assign({ className: "block" }, { children: "Get started with a free account today." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-8 flex justify-center" }, { children: [_jsx("div", __assign({ className: "inline-flex rounded-md shadow" }, { children: _jsx(Link, __assign({ to: "/register", className: "inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700" }, { children: "Get started" }), void 0) }), void 0), _jsx("div", __assign({ className: "ml-3 inline-flex" }, { children: _jsx(Link, __assign({ to: "/pricing", className: "inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200" }, { children: "Learn More" }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default RegisterCTA;
