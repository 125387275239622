var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { QUERY, QUERY_RESULTS, SEARCHING } from "../actions";
var queryReducer = function (state, action) {
    switch (action.type) {
        case QUERY:
            return __assign(__assign({}, state), { query: action.payload });
        case QUERY_RESULTS:
            return __assign(__assign({}, state), { results: action.payload });
        case SEARCHING:
            return __assign(__assign({}, state), { searching: action.payload });
        default:
            return state;
    }
};
export default queryReducer;
