var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { LockClosed } from "heroicons-react";
import { Currency } from "@domainpage/shared/src";
import punycode from "punycode.js";
import { formatNumberToCurrency } from "../utils";
import Tag from "./Tag";
var Card = function (_a) {
    var name = _a.name, description = _a.description, sellingPrice = _a.sellingPrice, priceHidden = _a.priceHidden, username = _a.username, callback = _a.callback, views = _a.views, _b = _a.theme, theme = _b === void 0 ? "gray" : _b;
    return (_jsxs("div", __assign({ className: "bg-white h-full shadow-md hover:shadow-sm transition:shadow duration-500 ease-in-out border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200" }, { children: [_jsxs("div", __assign({ className: "py-4 px-6 max-h-full h-60 flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex align-middle justify-between" }, { children: [views > -1 ?
                                _jsx("a", __assign({ target: "_blank", href: "https://".concat(username, ".").concat(process.env.REACT_APP_ROOT_URL, "/").concat(name) }, { children: _jsx(Tag, { color: "blue", text: views === 1 ? "".concat(views, " view") : "".concat(views, " views") }, void 0) }), void 0) : null, username ? _jsx("a", __assign({ target: "_blank", href: "https://".concat(username, ".").concat(process.env.REACT_APP_ROOT_URL) }, { children: _jsx(Tag, { color: "green", text: username }, void 0) }), void 0) : null] }), void 0), _jsx("h2", __assign({ className: "break-all text-md leading-6 font-semibold text-gray-700 mt-2" }, { children: punycode.toUnicode(name) }), void 0), _jsx("p", __assign({ className: "truncate flex-grow mt-4 text-sm text-gray-500" }, { children: description ? description : "Inquire about this domain today" }), void 0), sellingPrice && !priceHidden ? (_jsx("button", __assign({ onClick: callback ? function () { return callback(name); } : function () {
                        }, className: "self-end domain-button bottom-0 block w-full border-2 border-".concat(theme, "-600 rounded-md py-2 ease-in-out duration-500 hover:ease-in-out text-sm font-semibold hover:text-").concat(theme, "-600 text-white text-center bg-").concat(theme, "-600 hover:bg-transparent shadow-").concat(theme, " hover:shadow-none focus:outline-none focus:ring focus:ring-").concat(theme) }, { children: sellingPrice ? "Buy For ".concat(formatNumberToCurrency(sellingPrice, Currency.CURRENCY_WITHOUT_DECIMAL)) : "Contact Now" }), void 0)) : (_jsx("button", __assign({ onClick: callback ? function () { return callback(name); } : function () {
                        }, className: "self-end domain-button bottom-0 block w-full bg-transparent border-2 border-".concat(theme, "-600 rounded-md py-2 ease-in-out duration-500 hover:ease-in-out text-sm font-semibold text-").concat(theme, "-600 hover:text-white text-center hover:bg-").concat(theme, "-600 hover:shadow-").concat(theme, " focus:outline-none focus:ring focus:ring-").concat(theme) }, { children: sellingPrice && !priceHidden ? "Buy For ".concat(formatNumberToCurrency(sellingPrice, Currency.CURRENCY_WITHOUT_DECIMAL)) : "Contact Now" }), void 0))] }), void 0), _jsx("div", __assign({ className: "py-4 px-6 w-full" }, { children: _jsx("a", __assign({ href: "https://www.whatsmydns.net/#TXT/".concat(name), target: "_blank" }, { children: _jsxs("button", __assign({ type: "button", className: "w-full inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-".concat(theme, "-500") }, { children: [_jsx(LockClosed, { className: "h-3 w-3" }, void 0), _jsx("p", __assign({ className: "m-auto" }, { children: "Verify Domain Ownership" }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default Card;
