var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import AppLink from "../../components/AppLink";
import DatePickerCell from "../../components/table/DatePickerCell";
import EditableCell from "../../components/table/EditableCell";
import SelectableCell from "../../components/table/SelectableCell";
import ToggleBuyItNowCell from "../../components/table/ToggleBuyItNowCell";
import TogglePublicCell from "../../components/table/TogglePublicCell";
import DomainPriceVisibilityToggle from "../../components/DomainPriceVisibilityToggle";
import { Header, Redirect } from "@domainpage/shared/src";
export var domainTableColumnSchema = function (selectItems) {
    var columns = [
        {
            id: Header.VIEWS_COLUMN,
            Header: Header.VIEWS_DISPLAY,
            accessor: Header.VIEWS_COLUMN,
            Cell: function (_a) {
                var cell = _a.cell;
                return _jsx("div", __assign({ className: "text-center" }, { children: cell.value }), void 0);
            }
        },
        {
            id: Header.NAME_COLUMN,
            Header: Header.NAME_DISPLAY,
            accessor: Header.NAME_COLUMN,
            Cell: function (_a) {
                var cell = _a.cell;
                return (_jsx(AppLink, __assign({ className: "w-full h-full font-medium", to: "".concat(Redirect.DOMAINS, "/").concat(cell.value) }, { children: cell.value }), void 0));
            }
        },
        {
            id: Header.SELLING_PRICE_COLUMN,
            Header: Header.SELLING_PRICE_DISPLAY,
            accessor: Header.SELLING_PRICE_COLUMN,
            Cell: function (props) { return EditableCell(__assign(__assign({}, props), { numeric: true })); },
        },
        {
            id: Header.PRICE_HIDDEN_COLUMN,
            Header: Header.PRICE_HIDDEN_DISPLAY,
            accessor: Header.PRICE_HIDDEN_COLUMN,
            Cell: DomainPriceVisibilityToggle,
        },
        {
            id: Header.DESCRIPTION_COLUMN,
            Header: Header.DESCRIPTION_DISPLAY,
            accessor: Header.DESCRIPTION_COLUMN,
            Cell: EditableCell,
        },
        {
            id: Header.EXPIRATION_COLUMN,
            Header: Header.EXPIRATION_DISPLAY,
            accessor: Header.EXPIRATION_COLUMN,
            Cell: DatePickerCell,
        },
        {
            id: Header.RENEWAL_COST_COLUMN,
            Header: Header.RENEWAL_COST_DISPLAY,
            accessor: Header.RENEWAL_COST_COLUMN,
            Cell: function (props) { return EditableCell(__assign(__assign({}, props), { numeric: true })); },
        },
        {
            id: Header.PURCHASE_DATE_COLUMN,
            Header: Header.PURCHASE_DATE_DISPLAY,
            accessor: Header.PURCHASE_DATE_COLUMN,
            Cell: DatePickerCell,
        },
        {
            id: Header.REGISTRAR_COLUMN,
            Header: Header.REGISTRAR_DISPLAY,
            accessor: Header.REGISTRAR_COLUMN,
            Cell: function (props) { return SelectableCell(__assign(__assign({}, props), { selectItems: selectItems })); },
        },
        {
            id: Header.PUBLIC_COLUMN,
            Header: Header.PUBLIC_DISPLAY,
            accessor: Header.PUBLIC_COLUMN,
            Cell: TogglePublicCell,
        },
        {
            id: Header.BUY_IT_NOW_COLUMN,
            Header: Header.BUY_IT_NOW_DISPLAY,
            accessor: Header.BUY_IT_NOW_COLUMN,
            Cell: ToggleBuyItNowCell,
        },
        {
            id: Header.PURCHASE_URL_COLUMN,
            Header: Header.PURCHASE_URL_DISPLAY,
            accessor: Header.PURCHASE_URL_COLUMN,
            Cell: EditableCell,
        },
        {
            id: Header.FINANCE_URL_COLUMN,
            Header: Header.FINANCE_URL_DISPLAY,
            accessor: Header.FINANCE_URL_COLUMN,
            Cell: EditableCell,
        },
        {
            id: Header.LEASE_URL_COLUMN,
            Header: Header.LEASE_URL_DISPLAY,
            accessor: Header.LEASE_URL_COLUMN,
            Cell: EditableCell,
        },
    ];
    return columns;
};
