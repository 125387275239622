var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useRef } from "react";
var IndeterminateCheckbox = forwardRef(
// @ts-ignore
function (_a, ref) {
    var indeterminate = _a.indeterminate, rest = __rest(_a, ["indeterminate"]);
    var defaultRef = useRef();
    var resolvedRef = ref || defaultRef;
    useEffect(function () {
        // @ts-ignore
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "flex items-center justify-center" }, { children: _jsx("input", __assign({ type: "checkbox", className: "focus:ring-indigo-500 text-indigo-600 border border-gray-300 rounded", 
                // @ts-ignore
                ref: resolvedRef }, rest), void 0) }), void 0) }, void 0));
});
export default IndeterminateCheckbox;
