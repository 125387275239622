var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Header, IgnoredTableHeaders } from "@domainpage/shared/src/constants/headers";
import { useEffect, useRef, useState } from "react";
import { Menu } from "heroicons-react";
var MultiSelectDropdown = function (_a) {
    var tableHeadersWithDisplayStatus = _a.tableHeadersWithDisplayStatus, setTableHeadersWithDisplayStatus = _a.setTableHeadersWithDisplayStatus;
    var _b = useState(false), toggleDropdown = _b[0], setToggleDropdown = _b[1];
    var _c = useState([]), displayedHeaders = _c[0], setDisplayedHeaders = _c[1];
    var dropDownRef = useRef();
    useEffect(function () {
        setDisplayedHeaders(tableHeadersWithDisplayStatus);
        localStorage.setItem("__domainpage_headers", displayedHeaders.toString());
    }, [displayedHeaders, tableHeadersWithDisplayStatus]);
    useEffect(function () {
        var handleClickEvent = function (event) {
            // @ts-ignore
            if (dropDownRef.current && !event.target.classList.contains("tableheader-dropdown")) {
                setToggleDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickEvent);
        return function () {
            document.removeEventListener("mousedown", handleClickEvent);
        };
    }, [dropDownRef]);
    var handleToggleDisplayField = function (event) {
        var target = event.target.textContent;
        var temp = displayedHeaders.map(function (header) {
            if (header.displayName === target) {
                return {
                    name: header.name,
                    displayName: header.displayName,
                    column: header.column,
                    display: !header.display,
                };
            }
            return header;
        });
        setTableHeadersWithDisplayStatus(temp);
    };
    var renderTableHeaderList = function (displayedHeaders) {
        return displayedHeaders.map(function (tableHeader, key) {
            if (IgnoredTableHeaders.includes(tableHeader.column))
                return;
            if (tableHeader.column === Header.NAME_COLUMN)
                return;
            return tableHeader.display ? (_jsxs("li", __assign({ "data-tableheader": key + 1, onClick: function (e) { return handleToggleDisplayField(e); }, id: "listbox-item-".concat(key), role: "option", className: "tableheader-dropdown text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" }, { children: [_jsxs("div", __assign({ className: "tableheader-dropdown flex items-center space-x-3" }, { children: [_jsx("span", { "aria-label": "Online", className: "tableheader-dropdown bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full" }, void 0), _jsx("span", __assign({ className: "tableheader-dropdown font-semibold block truncate" }, { children: tableHeader.displayName }), void 0)] }), void 0), _jsx("span", __assign({ className: "tableheader-dropdown absolute inset-y-0 right-0 flex items-center px-4" }, { children: _jsx("svg", __assign({ className: "tableheader-dropdown h-3 w-3", viewBox: "0 0 20 20", fill: "currentColor" }, { children: _jsx("path", { fillRule: "evenodd", d: "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z", clipRule: "evenodd" }, void 0) }), void 0) }), void 0)] }), key)) : (_jsx("li", __assign({ "data-tableheader": key + 1, onClick: function (e) { return handleToggleDisplayField(e); }, id: "listbox-item-".concat(key), role: "option", className: "tableheader-dropdown text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" }, { children: _jsxs("div", __assign({ className: "tableheader-dropdown flex items-center space-x-3" }, { children: [_jsx("span", { "aria-label": "Not Online", className: "tableheader-dropdown bg-gray-200 flex-shrink-0 inline-block h-2 w-2 rounded-full" }, void 0), _jsx("span", __assign({ className: "tableheader-dropdown font-normal block truncate" }, { children: tableHeader.displayName }), void 0)] }), void 0) }), key));
        });
    };
    return (_jsx("div", __assign({ onClick: function () { return setToggleDropdown(true); }, className: "z-10 space-y-1 my-3 w-auto float-left tableheader-dropdown" }, { children: _jsxs("div", __assign({ className: "z-0 relative tableheader-dropdown" }, { children: [_jsx("span", __assign({ className: "inline-flex rounded-md shadow-sm tableheader-dropdown" }, { children: _jsxs("button", __assign({ type: "button", className: "tableheader-dropdown inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: [_jsx(Menu, { className: "h-3 w-3 mr-2 tableheader-dropdown" }, void 0), " Toggle Fields"] }), void 0) }), void 0), toggleDropdown ? (_jsx("div", __assign({ ref: dropDownRef, className: "z-50 tableheader-dropdown absolute mt-1 rounded-md bg-white shadow-lg" }, { children: _jsx("ul", __assign({ "data-tableheader": 0, tabIndex: -1, role: "listbox", "aria-labelledby": "listbox-label", "aria-activedescendant": "listbox-item", className: "tableheader-dropdown rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5" }, { children: renderTableHeaderList(displayedHeaders) }), void 0) }), void 0)) : null] }), void 0) }), void 0));
};
export default MultiSelectDropdown;
