var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Menu } from "heroicons-react";
function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(" ");
}
var MultiSelect = function (_a) {
    var data = _a.data, cb = _a.cb, _b = _a.ignored, ignored = _b === void 0 ? [] : _b;
    var _c = useState(data[0]), selected = _c[0], setSelected = _c[1];
    var helper = function (item) {
        setSelected(item.Header);
        cb(item.id);
    };
    return (_jsx(Listbox, __assign({ value: selected, onChange: helper }, { children: function (_a) {
            var open = _a.open;
            return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "relative" }, { children: [_jsxs(Listbox.Button, __assign({ className: "" }, { children: [_jsx("div", __assign({ className: "flex items-center" }, { children: _jsx("span", __assign({ className: "inline-flex rounded-md shadow-sm tableheader-dropdown" }, { children: _jsxs("button", __assign({ type: "button", className: "inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: [_jsx(Menu, { className: "h-3 w-3 mr-2 tableheader-dropdown" }, void 0), " Toggle Fields"] }), void 0) }), void 0) }), void 0), _jsx("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }, void 0)] }), void 0), _jsx(Transition, __assign({ show: open, as: Fragment, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Listbox.Options, __assign({ static: true, style: { width: 'fit-content' }, className: "absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-xs ring-1 ring-black ring-opacity-5 overflow-y-auto overflow-x-visible focus:outline-none w-full" }, { children: data.filter(function (item) { return !ignored.includes(item.id); }).map(function (item) { return (_jsx(Listbox.Option, __assign({ onClick: function (item) { return helper(item); }, className: function (_a) {
                                        var active = _a.active;
                                        return classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9');
                                    }, value: item }, { children: function (_a) {
                                        var selected = _a.selected, active = _a.active;
                                        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx("span", { className: classNames(item.isVisible ? 'bg-green-400' : 'bg-gray-200', 'flex-shrink-0 inline-block h-2 w-2 rounded-full'), "aria-hidden": "true" }, void 0), _jsxs("span", __assign({ className: classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate') }, { children: [item.Header, _jsxs("span", __assign({ className: "sr-only" }, { children: [" is ", item.isVisible ? 'visible' : 'hidden'] }), void 0)] }), void 0)] }), void 0), selected ? (_jsx("span", __assign({ className: classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4') }, { children: _jsx(CheckIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0) }), void 0)) : null] }, void 0));
                                    } }), item.id)); }) }), void 0) }), void 0)] }), void 0) }, void 0));
        } }), void 0));
};
export default MultiSelect;
