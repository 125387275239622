import { jsx as _jsx } from "react/jsx-runtime";
import { ExternalLink } from "heroicons-react";
import React from "react";
import ExternalLinkCell from "../../components/table/ExternalLinkCell";
import { Header } from "@domainpage/shared/src/constants/headers";
export var landerColumnSchema = {
    id: Header.LANDER,
    Header: _jsx(ExternalLink, { className: "h-4 w-4 text-center text-gray-400 flex items-center justify-center" }, void 0),
    Cell: ExternalLinkCell,
    totalWidth: 5
};
