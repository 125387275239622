var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trash } from "heroicons-react";
import React from "react";
import MultiSelect from "../../components/MultiSelect";
import { Header } from "@domainpage/shared/src/constants/headers";
export var selectItemDeleteSection = function (_a) {
    var displayDeleteConfirmation = _a.displayDeleteConfirmation, toggleAllPageRowsSelected = _a.toggleAllPageRowsSelected, setDisplayDeleteConfirmation = _a.setDisplayDeleteConfirmation, _b = _a.selectableRowTableOptions, deleteCallback = _b.deleteCallback, _c = _b.entity, entity = _c === void 0 ? { singular: "Domain", plural: "Domains" } : _c, selectedFlatRows = _a.selectedFlatRows, allColumns = _a.allColumns, toggleHideColumn = _a.toggleHideColumn;
    return selectedFlatRows.length > 0 ? (_jsx("div", { children: _jsx("span", __assign({ className: "inline-flex rounded-md" }, { children: _jsx("div", __assign({ className: "flex w-full space-x-4" }, { children: displayDeleteConfirmation ? (_jsxs("div", __assign({ className: "my-4 space-x-4" }, { children: [_jsx("button", __assign({ onClick: function () {
                                toggleAllPageRowsSelected(false);
                                setDisplayDeleteConfirmation(false);
                            }, className: "inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: "Cancel" }), void 0), _jsx("button", __assign({ onClick: function () { return deleteCallback(selectedFlatRows); }, className: "inline-flex items-center px-2 py-1 border border-red-500 shadow-sm text-xs font-medium rounded text-red-700 bg-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600" }, { children: selectedFlatRows.length > 1
                                ? "Confirm Deletion of ".concat(selectedFlatRows.length, " ").concat(entity.plural)
                                : "Confirm Deletion of 1 ".concat(entity.singular) }), void 0)] }), void 0)) : (_jsxs("button", __assign({ onClick: function () { return setDisplayDeleteConfirmation(true); }, type: "button", className: "inline-flex items-center px-2 py-1 shadow-sm border border-red-500 shadow-sm text-xs font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400" }, { children: [_jsx(Trash, { className: "h-3 w-3 mr-2" }, void 0), selectedFlatRows.length > 1
                            ? "Delete ".concat(selectedFlatRows.length, " ").concat(entity.plural)
                            : "Delete ".concat(entity.singular)] }), void 0)) }), void 0) }), void 0) }, void 0)) : (_jsx(MultiSelect, { data: allColumns, cb: toggleHideColumn, ignored: [Header.NAME_COLUMN, Header.TOGGLE_BUTTON, Header.LANDER] }, void 0));
};
