var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import { BriefcaseOutline, ChartSquareBarOutline, ClipboardCheckOutline, ClockOutline, EyeOutline, } from "heroicons-react";
import debounce from "lodash.debounce";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SEARCHING } from "../../actions";
import PillGroup from "../../components/common/PillGroup";
import Stats from "../../components/Stats";
import AuthContext from "../../context/AuthContext";
import DashboardContext from "../../context/DashboardContext";
import QueryContext from "../../context/QueryContext";
import { Interval, Paginate, Registrars, Sort } from "@domainpage/shared/src";
import { emitToastNotification, formatNumberToCurrency, mapIntervalToIntervalDisplay } from "../../utils";
import DomainTable from "../../components/DomainTable";
var DashboardHome = function () {
    var _a = useContext(DashboardContext), dashboard = _a.state, dispatch = _a.dispatch;
    var state = useContext(QueryContext).state;
    var query = state.query;
    var auth = useContext(AuthContext).state;
    var username = auth.user.username;
    var results = state.results;
    var _b = useState([]), domains = _b[0], setDomains = _b[1];
    var _c = useState(Paginate.PAGE), page = _c[0], setPage = _c[1];
    var _d = useState(Paginate.LIMIT), limit = _d[0], setLimit = _d[1];
    var _e = useState({}), paginateOptions = _e[0], setPaginateOptions = _e[1];
    var _f = useState({}), stats = _f[0], setStats = _f[1];
    var _g = useState(Sort.LATEST), sort = _g[0], setSort = _g[1];
    var _h = useState(Interval.MONTH), interval = _h[0], setInterval = _h[1];
    var DEBOUNCE_DELAY = 300;
    var handleDebouncedSearch = useCallback(debounce(function (_a) {
        var query = _a.query, page = _a.page, sort = _a.sort;
        return __awaiter(this, void 0, void 0, function () {
            var searchRequestPayload, res, data, payload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        searchRequestPayload = { query: query };
                        return [4 /*yield*/, fetch("/api/search?username=".concat(username, "&page=").concat(page, "&sort=").concat(sort, "&dashboard=true"), {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(searchRequestPayload),
                            })];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        data = _b.sent();
                        payload = data.payload;
                        setPaginateOptions(payload);
                        setDomains(payload.docs);
                        dispatch({ type: SEARCHING, payload: false });
                        return [2 /*return*/];
                }
            });
        });
    }, DEBOUNCE_DELAY), []);
    useEffect(function () {
        dispatch({ type: SEARCHING, payload: true });
        handleDebouncedSearch({ query: query, page: page, sort: sort });
    }, [query, page, sort]);
    var fetchDomainPortfolioStats = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/domains/portfolio-stats?interval=".concat(interval))];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    setStats(data.payload);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchDomainPortfolioStats().catch(function (err) { return err; });
    }, [interval]);
    var pillGroupItems = [
        { name: Interval.DAY_DISPLAY, value: Interval.DAY, current: false },
        { name: Interval.WEEK_DISPLAY, value: Interval.WEEK, current: false },
        { name: Interval.MONTH_DISPLAY, value: Interval.MONTH, current: true },
        { name: Interval.QUARTER_DISPLAY, value: Interval.QUARTER, current: false },
        { name: Interval.YEAR_DISPLAY, value: Interval.YEAR, current: false },
    ];
    var _j = useState(pillGroupItems), items = _j[0], setItems = _j[1];
    var intervalDisplay = mapIntervalToIntervalDisplay(interval);
    var handleIntervalSelect = function (interval) {
        setInterval(interval);
    };
    var portfolioData = [
        {
            name: "Domains",
            stat: stats === null || stats === void 0 ? void 0 : stats.totalDomains,
            icon: _jsx(ClipboardCheckOutline, { className: "h-6 w-6 text-gray-400" }, void 0),
        },
        {
            name: "Estimated Portfolio Value",
            stat: formatNumberToCurrency(stats === null || stats === void 0 ? void 0 : stats.totalPortfolioValue),
            icon: _jsx(BriefcaseOutline, { className: "h-6 w-6 text-gray-400" }, void 0),
        },
        {
            name: "Total Views",
            stat: stats === null || stats === void 0 ? void 0 : stats.totalViews,
            icon: _jsx(EyeOutline, { className: "h-6 w-6 text-gray-400" }, void 0),
        },
        {
            name: "Expirations this ".concat(intervalDisplay),
            stat: stats === null || stats === void 0 ? void 0 : stats.totalDomainExpirations,
            icon: _jsx(ClockOutline, { className: "h-6 w-6 text-gray-400" }, void 0),
        },
        {
            name: "Renewal Cost this ".concat(intervalDisplay),
            stat: formatNumberToCurrency(stats === null || stats === void 0 ? void 0 : stats.totalRenewalCost),
            icon: _jsx(CurrencyDollarIcon, { className: "h-6 w-6 text-gray-400" }, void 0),
        },
        {
            name: "Leads this ".concat(intervalDisplay),
            stat: stats === null || stats === void 0 ? void 0 : stats.totalLeads,
            icon: _jsx(ChartSquareBarOutline, { className: "h-6 w-6 text-gray-400" }, void 0),
            link: "/dashboard/leads",
        },
    ];
    var handleUpdate = function (domainObj) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = { domain: domainObj };
                    return [4 /*yield*/, fetch("/api/domains/edit", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(payload),
                        })];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    emitToastNotification(dispatch, data);
                    return [2 /*return*/];
            }
        });
    }); };
    var domainRegistrars = Registrars.map(function (registrar) {
        return { id: registrar.name, name: registrar.name, display: registrar.display };
    });
    return (_jsxs("div", __assign({ className: "mt-8" }, { children: [_jsxs("div", __assign({ className: "max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" }, { children: [_jsx("h2", __assign({ className: "text-lg leading-6 font-medium text-gray-900" }, { children: "Dashboard" }), void 0), _jsx("div", __assign({ className: "my-2" }, { children: _jsx(PillGroup, { items: items, itemsCallback: setItems, selectedCallback: handleIntervalSelect }, void 0) }), void 0), _jsx(Stats, { data: portfolioData }, void 0)] }), void 0), _jsx("h2", __assign({ className: "max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8" }, { children: results ? "Domain Names (".concat(results, ")") : "Domain Names" }), void 0), _jsx("div", __assign({ className: "max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mb-12" }, { children: _jsx("div", __assign({ className: "block" }, { children: _jsx(DomainTable, { pageCallback: setPage, sortCallback: setSort, paginateOptions: paginateOptions, handleUpdate: handleUpdate, selectItems: domainRegistrars, data: domains }, void 0) }), void 0) }), void 0)] }), void 0));
};
export default DashboardHome;
