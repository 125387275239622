var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable, usePagination, useRowSelect } from "react-table";
import { domainTableColumnSchema } from "../constants/table/domainTableColumnSchema";
import { domainTableSortBar } from "../constants/table/domainTableSortBar";
import { landerColumnSchema } from "../constants/table/landerColumnSchema";
import { selectableRowColumnSchema } from "../constants/table/selectableRowColumnSchema";
import { selectItemDeleteSection } from "../constants/table/selectItemDeleteSection";
import { Header, LocalStorage, PageDirection, Paginate } from "@domainpage/shared/src";
import { emitToastNotification } from "../utils";
import Skeleton from "./Skeleton";
import TablePagination from "./TablePagination";
import PillGroup from "./common/PillGroup";
import NotificationContext from "../context/NotificationContext";
var DomainTable = function (_a) {
    var data = _a.data, handleUpdate = _a.handleUpdate, selectItems = _a.selectItems, paginateOptions = _a.paginateOptions, pageCallback = _a.pageCallback, sortCallback = _a.sortCallback;
    var dispatch = useContext(NotificationContext).dispatch;
    var history = useHistory();
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), displayDeleteConfirmation = _c[0], setDisplayDeleteConfirmation = _c[1];
    var domains = useMemo(function () { return loading ? Array(30).fill({}) : data; }, [loading, data]);
    var handleSortSelect = function (selectedSort) {
        pageCallback(Paginate.PAGE);
        window.localStorage.setItem(LocalStorage.DASHBOARD_SORT_FILTER, selectedSort);
        sortCallback(selectedSort);
    };
    var handleDeleteDomains = function (domainObjArr) { return __awaiter(void 0, void 0, void 0, function () {
        var domainIdsToBeDeletedArr, params, res, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    domainIdsToBeDeletedArr = domainObjArr.map(function (domain) { return domain.original._id; });
                    params = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ payload: domainIdsToBeDeletedArr }),
                    };
                    return [4 /*yield*/, fetch("/api/domains/delete", params)];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    notification = _a.sent();
                    emitToastNotification(dispatch, notification);
                    history.go(0);
                    return [2 /*return*/];
            }
        });
    }); };
    var selectableRowTableOptions = { deleteCallback: handleDeleteDomains };
    var restoreOrSetDefaultSort = function (sortBar) {
        var cachedSortFilter = window.localStorage.getItem(LocalStorage.DASHBOARD_SORT_FILTER);
        var sortBarCopy = __spreadArray([], sortBar, true);
        if (cachedSortFilter) {
            sortBarCopy.map(function (sortFilter) { return sortFilter.current = sortFilter.value === cachedSortFilter; });
        }
        return sortBarCopy;
    };
    var _d = useState(function () { return restoreOrSetDefaultSort(domainTableSortBar); }), items = _d[0], setItems = _d[1];
    var handlePageChange = function (direction) {
        if (direction === PageDirection.PREV) {
            if (paginateOptions.hasPrevPage) {
                pageCallback(paginateOptions.prevPage);
            }
        }
        if (direction === PageDirection.NEXT) {
            if (paginateOptions.hasNextPage) {
                pageCallback(paginateOptions.nextPage);
            }
        }
    };
    var initialState = {
        pageIndex: paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.page,
        pageSize: paginateOptions === null || paginateOptions === void 0 ? void 0 : paginateOptions.pageSize,
        hiddenColumns: [
            Header.PURCHASE_DATE_COLUMN,
            Header.PURCHASE_URL_COLUMN,
            Header.DESCRIPTION_COLUMN,
            Header.RENEWAL_COST_COLUMN,
            Header.FINANCE_URL_COLUMN,
            Header.LEASE_URL_COLUMN,
        ],
    };
    var columns = useMemo(function () { return loading ? domainTableColumnSchema(selectItems).map(function (column) { return (__assign(__assign({}, column), { Cell: _jsx("div", __assign({ className: "p-2" }, { children: _jsx(Skeleton, {}, void 0) }), void 0) })); }) : domainTableColumnSchema(selectItems); }, [loading]);
    var _e = useTable({
        columns: columns,
        data: data,
        initialState: initialState,
        manualPagination: true,
        autoResetPage: true,
        pageCount: paginateOptions.totalPages,
        handleUpdate: handleUpdate,
        selectItems: selectItems,
    }, usePagination, useRowSelect, function (hooks) {
        hooks.visibleColumns.push(function (columns) { return __spreadArray([
            selectableRowColumnSchema,
            landerColumnSchema
        ], columns, true); });
    }), getTableProps = _e.getTableProps, headerGroups = _e.headerGroups, rows = _e.rows, prepareRow = _e.prepareRow, allColumns = _e.allColumns, toggleHideColumn = _e.toggleHideColumn, toggleAllPageRowsSelected = _e.toggleAllPageRowsSelected, selectedFlatRows = _e.selectedFlatRows;
    useEffect(function () {
        setTimeout(function () {
            setLoading(false);
        }, 500);
    }, [domains]);
    return (_jsxs("div", __assign({ className: "flex flex-col mt-2" }, { children: [_jsx("div", __assign({ className: "flex flex-wrap items-center space-x-4 sm:flex-1 sm:m-0 py-2" }, { children: _jsxs(Fragment, { children: [selectItems ? selectItemDeleteSection({
                            displayDeleteConfirmation: displayDeleteConfirmation,
                            toggleAllPageRowsSelected: toggleAllPageRowsSelected,
                            setDisplayDeleteConfirmation: setDisplayDeleteConfirmation,
                            selectableRowTableOptions: selectableRowTableOptions,
                            selectedFlatRows: selectedFlatRows,
                            allColumns: allColumns,
                            toggleHideColumn: toggleHideColumn,
                        }) : null, _jsx("div", __assign({ className: "my-4 xl:my-0" }, { children: _jsx(PillGroup, { items: items, selectedCallback: handleSortSelect, itemsCallback: setItems }, void 0) }), void 0)] }, void 0) }), void 0), _jsxs("div", __assign({ className: "align-middle shadow overflow-x-scroll" }, { children: [_jsx(TablePagination, { paginateOptions: paginateOptions, handlePageChange: handlePageChange, topSection: true }, void 0), _jsxs("table", __assign({}, getTableProps(function (table) { return ({
                        className: "min-w-full divide-y divide-gray-200",
                    }); }), { children: [_jsx("thead", { children: headerGroups.map(function (headerGroup, key) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(function (tr) {
                                    return ({
                                        key: key,
                                    });
                                }), { children: headerGroup.headers.map(function (column) { return (_jsx("th", __assign({}, column.getHeaderProps(function (column) {
                                        return ({
                                            key: column.key,
                                            className: "text-center px-2 py-4 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 bg-gray-50 overflow-hidden",
                                        });
                                    }), { children: column.render("Header") }), void 0)); }) }), void 0)); }) }, void 0), _jsx("tbody", { children: rows.map(function (row) {
                                    prepareRow(row);
                                    return (_jsx("tr", __assign({}, row.getRowProps(function (row, meta) {
                                        return ({
                                            key: "".concat(meta.row.original.name, "-").concat(meta.row.id),
                                            className: "group",
                                        });
                                    }), { children: 
                                        // font-medium pl-4 relative bg-white border border-gray-200 group-hover:bg-gray-50 whitespace-nowrap text-sm text-gray-900
                                        row.cells.map(function (cell, index) {
                                            return _jsx("td", __assign({}, cell.getCellProps(function (cell, meta) {
                                                return ({
                                                    key: "".concat(meta.cell.row.original.name, "-").concat(meta.cell.row.id, "-").concat(meta.cell.value, "-").concat(index),
                                                    className: "py-1 px-2 relative bg-white border border-gray-200 group-hover:bg-gray-50 whitespace-nowrap text-sm text-gray-900",
                                                });
                                            }), { children: cell.render("Cell") }), cell.key);
                                        }) }), void 0));
                                }) }, void 0)] }), void 0), _jsx(TablePagination, { paginateOptions: paginateOptions, handlePageChange: handlePageChange, topSection: false }, void 0)] }), void 0)] }), void 0));
};
export default DomainTable;
