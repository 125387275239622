export var Interval;
(function (Interval) {
    Interval["ALL"] = "ALL";
    Interval["DAY"] = "DAY";
    Interval["DAY_DISPLAY"] = "Day";
    Interval["DAILY"] = "DAILY";
    Interval["WEEK"] = "WEEK";
    Interval["WEEK_DISPLAY"] = "Week";
    Interval["WEEKLY"] = "WEEKLY";
    Interval["MONTH"] = "MONTH";
    Interval["MONTH_DISPLAY"] = "Month";
    Interval["MONTHLY"] = "MONTHLY";
    Interval["MONTHLY_DISPLAY"] = "Monthly";
    Interval["QUARTER"] = "QUARTER";
    Interval["QUARTER_DISPLAY"] = "Quarter";
    Interval["QUARTERLY"] = "QUARTERLY";
    Interval["QUARTERLY_DISPLAY"] = "Quarterly";
    Interval["YEAR"] = "YEAR";
    Interval["YEAR_DISPLAY"] = "Year";
    Interval["YEARLY"] = "YEARLY";
    Interval["YEARLY_DISPLAY"] = "Yearly";
    Interval["ANNUAL"] = "ANNUAL";
    Interval["ANNUAL_DISPLAY"] = "Annual";
    Interval["ANNUALLY"] = "ANNUALLY";
    Interval["ANNUALLY_DISPLAY"] = "Annually";
    Interval["LIFETIME"] = "LIFETIME";
    Interval["LIFETIME_DISPLAY"] = "Lifetime";
    Interval["UNLIMITED"] = "UNLIMITED";
    Interval["UNLIMITED_DISPLAY"] = "Unlimited";
})(Interval || (Interval = {}));
