var _a;
export var Header;
(function (Header) {
    Header["NAME"] = "NAME";
    Header["NAME_DISPLAY"] = "Domain";
    Header["NAME_COLUMN"] = "name";
    Header["DESCRIPTION"] = "DESCRIPTION";
    Header["DESCRIPTION_DISPLAY"] = "Description";
    Header["DESCRIPTION_COLUMN"] = "description";
    Header["SELLING_PRICE"] = "SELLING_PRICE";
    Header["SELLING_PRICE_DISPLAY"] = "Selling Price";
    Header["SELLING_PRICE_COLUMN"] = "sellingPrice";
    Header["DOMAIN_COST"] = "DOMAIN_COST";
    Header["DOMAIN_COST_DISPLAY"] = "Domain Cost";
    Header["DOMAIN_COST_COLUMN"] = "domainCost";
    Header["EXPIRATION"] = "EXPIRATION";
    Header["EXPIRATION_DISPLAY"] = "Expiration";
    Header["EXPIRATION_COLUMN"] = "expiration";
    Header["FORWARDING"] = "FORWARDING";
    Header["FORWARDING_COLUMN"] = "forwarding";
    Header["FORWARDING_DISPLAY"] = "Forwarding";
    Header["PUBLIC"] = "PUBLIC";
    Header["PUBLIC_COLUMN"] = "public";
    Header["PUBLIC_DISPLAY"] = "Public";
    Header["PURCHASE_DATE"] = "PURCHASE_DATE";
    Header["PURCHASE_DATE_COLUMN"] = "purchaseDate";
    Header["PURCHASE_DATE_DISPLAY"] = "Purchase Date";
    Header["SALE_DATE"] = "SALE_DATE";
    Header["SALE_DATE_COLUMN"] = "saleDate";
    Header["SALE_DATE_DISPLAY"] = "Sale Date";
    Header["BUY_IT_NOW"] = "BUY_IT_NOW";
    Header["BUY_IT_NOW_COLUMN"] = "buyItNow";
    Header["BUY_IT_NOW_DISPLAY"] = "BIN";
    Header["LANDER"] = "lander";
    Header["LANDER_DISPLAY"] = "Lander";
    Header["LANDER_COLUMN"] = "lander";
    Header["LEADS"] = "LEADS";
    Header["LEADS_COLUMN"] = "leads";
    Header["LEADS_DISPLAY"] = "Leads";
    Header["RENEWAL_COST"] = "RENEWAL_COST";
    Header["RENEWAL_COST_DISPLAY"] = "Renewal Cost";
    Header["RENEWAL_COST_COLUMN"] = "renewalCost";
    Header["REGISTRAR"] = "REGISTRAR";
    Header["REGISTRAR_DISPLAY"] = "Registrar";
    Header["REGISTRAR_COLUMN"] = "registrar";
    Header["FINANCE_URL"] = "FINANCE_URL";
    Header["FINANCE_URL_DISPLAY"] = "Finance URL";
    Header["FINANCE_URL_COLUMN"] = "financeUrl";
    Header["LEASE_URL"] = "LEASE_URL";
    Header["LEASE_URL_DISPLAY"] = "Lease URL";
    Header["LEASE_URL_COLUMN"] = "leaseUrl";
    Header["PURCHASE_URL"] = "PURCHASE_URL";
    Header["PURCHASE_URL_DISPLAY"] = "Purchase URL";
    Header["PURCHASE_URL_COLUMN"] = "purchaseUrl";
    Header["SOLD"] = "SOLD";
    Header["SOLD_DISPLAY"] = "Sold";
    Header["SOLD_COLUMN"] = "sold";
    Header["TLD"] = "TLD";
    Header["TLD_DISPLAY"] = "TLD";
    Header["TLD_COLUMN"] = "tld";
    Header["VIEWS"] = "VIEWS";
    Header["VIEWS_DISPLAY"] = "Views";
    Header["VIEWS_COLUMN"] = "views";
    Header["TOGGLE_BUTTON"] = "toggle";
    Header["PRICE_HIDDEN"] = "PRICE_HIDDEN";
    Header["PRICE_HIDDEN_DISPLAY"] = "Hide Price";
    Header["PRICE_HIDDEN_COLUMN"] = "priceHidden";
    Header["AFTERNIC_NAME_COLUMN"] = "*Name (Required)";
    Header["AFTERNIC_SELLING_PRICE_COLUMN"] = "Buy Now Price";
    Header["GODADDY_NAME_COLUMN"] = "Domain Name";
    Header["GODADDY_EXPIRATION_COLUMN"] = "Expiration";
    Header["NAMECHEAP_NAME_COLUMN"] = "Name";
    Header["NAMECHEAP_EXPIRATION_COLUMN"] = "Expiration Date";
    Header["DAN_NAME_COLUMN"] = "domain name";
    Header["DAN_SELLING_PRICE_COLUMN"] = "buy now";
    Header["DAN_DESCRIPTION_COLUMN"] = "description";
    Header["EFTY_NAME_COLUMN"] = "Domain";
    Header["EFTY_DOMAIN_COST_COLUMN"] = "Purchase price";
    Header["EFTY_SELLING_PRICE_COLUMN"] = "BIN Price";
    Header["EFTY_DESCRIPTION_COLUMN"] = "Description";
})(Header || (Header = {}));
export var LeadHeader;
(function (LeadHeader) {
    LeadHeader["DOMAIN"] = "DOMAIN";
    LeadHeader["DOMAIN_COLUMN"] = "domain";
    LeadHeader["DOMAIN_DISPLAY"] = "Domain";
    LeadHeader["NAME"] = "NAME";
    LeadHeader["NAME_COLUMN"] = "name";
    LeadHeader["NAME_DISPLAY"] = "Name";
    LeadHeader["EMAIL"] = "EMAIL";
    LeadHeader["EMAIL_COLUMN"] = "email";
    LeadHeader["EMAIL_DISPLAY"] = "Email";
    LeadHeader["MESSAGE"] = "MESSAGE";
    LeadHeader["MESSAGE_COLUMN"] = "message";
    LeadHeader["MESSAGE_DISPLAY"] = "Message";
    LeadHeader["PHONE"] = "PHONE";
    LeadHeader["PHONE_COLUMN"] = "phone";
    LeadHeader["PHONE_DISPLAY"] = "Phone";
    LeadHeader["OFFER"] = "OFFER";
    LeadHeader["OFFER_COLUMN"] = "offer";
    LeadHeader["OFFER_DISPLAY"] = "Offer";
})(LeadHeader || (LeadHeader = {}));
export var SalesHeader;
(function (SalesHeader) {
    SalesHeader["DOMAIN"] = "DOMAIN";
    SalesHeader["DOMAIN_COLUMN"] = "domain";
    SalesHeader["DOMAIN_DISPLAY"] = "Domain";
})(SalesHeader || (SalesHeader = {}));
export var IgnoredTableHeaders = ["_id", "createdDate", "_v", "owner", "page"];
export var IgnoredBulkUploadTableHeaders = ["_id", "createdDate", "_v", "owner", "page", "forwarding"];
export var Headers = [
    {
        name: Header.NAME,
        displayName: Header.NAME_DISPLAY,
        column: Header.NAME_COLUMN,
        mappedColumns: [Header.NAME_COLUMN, Header.NAMECHEAP_NAME_COLUMN, Header.GODADDY_NAME_COLUMN, Header.AFTERNIC_NAME_COLUMN, Header.DAN_NAME_COLUMN, Header.EFTY_NAME_COLUMN],
        display: true
    },
    {
        name: Header.EXPIRATION,
        displayName: Header.EXPIRATION_DISPLAY,
        column: Header.EXPIRATION_COLUMN,
        mappedColumns: [Header.EXPIRATION_COLUMN, Header.GODADDY_EXPIRATION_COLUMN, Header.NAMECHEAP_EXPIRATION_COLUMN],
        display: true,
    },
    {
        name: Header.DESCRIPTION,
        displayName: Header.DESCRIPTION_DISPLAY,
        column: Header.DESCRIPTION_COLUMN,
        mappedColumns: [Header.DESCRIPTION_COLUMN, Header.DAN_DESCRIPTION_COLUMN, Header.EFTY_DESCRIPTION_COLUMN],
        display: false,
    },
    {
        name: Header.PURCHASE_DATE,
        displayName: Header.PURCHASE_DATE_DISPLAY,
        column: Header.PURCHASE_DATE_COLUMN,
        mappedColumns: [Header.PURCHASE_DATE_COLUMN],
        display: false,
    },
    {
        name: Header.SELLING_PRICE,
        displayName: Header.SELLING_PRICE_DISPLAY,
        column: Header.SELLING_PRICE_COLUMN,
        mappedColumns: [Header.SELLING_PRICE_COLUMN, Header.AFTERNIC_SELLING_PRICE_COLUMN, Header.DAN_SELLING_PRICE_COLUMN, Header.EFTY_SELLING_PRICE_COLUMN],
        display: true,
    },
    {
        name: Header.DOMAIN_COST,
        displayName: Header.DOMAIN_COST_DISPLAY,
        column: Header.DOMAIN_COST_COLUMN,
        mappedColumns: [Header.DOMAIN_COST_COLUMN, Header.EFTY_DOMAIN_COST_COLUMN],
        display: false,
    },
    {
        name: Header.REGISTRAR,
        displayName: Header.REGISTRAR_DISPLAY,
        column: Header.REGISTRAR_COLUMN,
        mappedColumns: [Header.REGISTRAR_COLUMN],
        display: false
    },
    {
        name: Header.PURCHASE_URL,
        displayName: Header.PURCHASE_URL_DISPLAY,
        column: Header.PURCHASE_URL_COLUMN,
        mappedColumns: [Header.PURCHASE_URL_COLUMN],
        display: false,
    },
    {
        name: Header.FINANCE_URL,
        displayName: Header.FINANCE_URL_DISPLAY,
        column: Header.FINANCE_URL_COLUMN,
        mappedColumns: [Header.FINANCE_URL_COLUMN],
        display: false,
    },
    {
        name: Header.LEASE_URL,
        displayName: Header.LEASE_URL_DISPLAY,
        column: Header.LEASE_URL_COLUMN,
        mappedColumns: [Header.LEASE_URL_COLUMN],
        display: false
    },
    {
        name: Header.PUBLIC,
        displayName: Header.PUBLIC_DISPLAY,
        column: Header.PUBLIC_COLUMN,
        mappedColumns: [Header.PUBLIC_COLUMN],
        display: true,
    },
    {
        name: Header.BUY_IT_NOW,
        displayName: Header.BUY_IT_NOW_DISPLAY,
        column: Header.BUY_IT_NOW_COLUMN,
        mappedColumns: [Header.BUY_IT_NOW_COLUMN],
        display: true,
    },
];
export var BulkHeaders = (_a = {},
    _a[Header.NAME] = {
        mappedColumns: [Header.NAME_COLUMN, Header.GODADDY_NAME_COLUMN, Header.NAMECHEAP_NAME_COLUMN, Header.DAN_NAME_COLUMN, Header.EFTY_NAME_COLUMN],
    },
    _a[Header.EXPIRATION] = {
        mappedColumns: [Header.EXPIRATION_COLUMN, Header.GODADDY_EXPIRATION_COLUMN, Header.NAMECHEAP_EXPIRATION_COLUMN],
    },
    _a[Header.DESCRIPTION] = {
        mappedColumns: [Header.DESCRIPTION_COLUMN, Header.DAN_DESCRIPTION_COLUMN, Header.EFTY_DESCRIPTION_COLUMN],
    },
    _a[Header.SELLING_PRICE] = {
        mappedColumns: [Header.SELLING_PRICE_COLUMN, Header.SELLING_PRICE_COLUMN, Header.DAN_SELLING_PRICE_COLUMN, Header.EFTY_SELLING_PRICE_COLUMN],
    },
    _a[Header.DOMAIN_COST] = {
        mappedColumns: [Header.DOMAIN_COST_COLUMN, Header.EFTY_DOMAIN_COST_COLUMN],
    },
    _a[Header.REGISTRAR] = {
        mappedColumns: [Header.REGISTRAR_COLUMN],
    },
    _a[Header.PURCHASE_URL] = {
        mappedColumns: [Header.PURCHASE_URL_COLUMN],
    },
    _a[Header.FINANCE_URL] = {
        mappedColumns: [Header.FINANCE_URL_COLUMN],
    },
    _a[Header.LEASE_URL] = {
        mappedColumns: [Header.LEASE_URL_COLUMN],
    },
    _a[Header.PUBLIC] = {
        mappedColumns: [Header.PUBLIC_COLUMN],
    },
    _a[Header.BUY_IT_NOW] = {
        mappedColumns: [Header.BUY_IT_NOW_COLUMN],
    },
    _a);
