import { format as dateFormat } from "date-fns";
export var handleDateFormatting = function (date) {
    try {
        return dateFormat(Date.parse(date), "MM/dd/yy");
    }
    catch (e) {
        // console.log('Error parsing domain expiration date:', e)
        return date;
    }
};
export var handleLeadCreatedDate = function (date) {
    try {
        return dateFormat(Date.parse(date), "MM/dd/yy");
    }
    catch (e) {
        // console.log('Error parsing lead created date:', e)
        return date;
    }
};
