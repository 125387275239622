var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { Interval, Plan, planOneFeatures, planTwoFeatures } from "../constants";
import PricingCard from "./PricingCard";
var PricingSection = function (_a) {
    var callback = _a.callback, _b = _a.dashboard, dashboard = _b === void 0 ? false : _b, _c = _a.hideButton, hideButton = _c === void 0 ? false : _c;
    var _d = useState(Interval.YEARLY), interval = _d[0], setInterval = _d[1];
    var handleChangeInterval = function (interval) {
        return setInterval(interval);
    };
    var selectedIntervalStyle = "relative w-1/2 bg-indigo-600 text-white rounded-md shadow py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8";
    var unSelectedIntervalStyle = "ml-0.5 relative w-1/2 bg-gray-100 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8";
    var calculateAnnualSavings = function (monthlyPrice, annualPrice) {
        var MONTHS_PER_YEAR = 12;
        var valueOutOfOneHundred = (annualPrice / (monthlyPrice * MONTHS_PER_YEAR)) * 100;
        return (100 - valueOutOfOneHundred).toFixed(2);
    };
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "sm:flex sm:flex-col sm:align-center" }, { children: _jsxs("div", __assign({ className: "relative self-center mt-6 bg-white shadow rounded-lg space-x-2 p-2 flex sm:mt-8" }, { children: [_jsxs("button", __assign({ onClick: function () { return handleChangeInterval(Interval.MONTHLY); }, type: "button", className: interval === Interval.MONTHLY ? selectedIntervalStyle : unSelectedIntervalStyle }, { children: [Interval.MONTHLY_DISPLAY, " billing"] }), void 0), _jsxs("button", __assign({ onClick: function () { return handleChangeInterval(Interval.YEARLY); }, type: "button", className: interval === Interval.YEARLY ? selectedIntervalStyle : unSelectedIntervalStyle }, { children: [Interval.YEARLY_DISPLAY, " billing"] }), void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-2" }, { children: [_jsx(PricingCard, { callback: callback, hideButton: hideButton, title: Plan.PLAN_ONE_DISPLAY, description: Plan.PLAN_ONE_DESCRIPTION, price: Plan.PLAN_ONE_MONTHLY_PRICE, plan: Plan.PLAN_ONE, interval: interval, featuresArr: planOneFeatures }, Plan.PLAN_ONE), _jsx(PricingCard, { callback: callback, title: Plan.PLAN_TWO_DISPLAY, description: Plan.PLAN_TWO_DESCRIPTION, price: interval === Interval.MONTHLY ? Plan.PLAN_TWO_MONTHLY_PRICE : Plan.PLAN_TWO_ANNUAL_PRICE, featuresArr: planTwoFeatures, plan: Plan.PLAN_TWO, interval: interval, hideButton: hideButton, annualSavingsText: "".concat(calculateAnnualSavings(Plan.PLAN_TWO_MONTHLY_PRICE, Plan.PLAN_TWO_ANNUAL_PRICE), "% Discount!"), highlighted: true }, Plan.PLAN_TWO)] }), void 0)] }, void 0));
};
export default PricingSection;
