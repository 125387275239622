var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExternalLinkOutline } from "heroicons-react";
import React from "react";
import AnimatedShapes from "../../assets/img/animated-squares.svg";
import OpenSourceDomainToolsImage from "../../assets/img/domainpage-open-source-domain-tools.jpg";
var OpenSourceBanner = function () {
    return (_jsx("div", __assign({ className: "" }, { children: _jsx("div", __assign({ className: "max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8" }, { children: _jsxs("div", __assign({ style: { backgroundImage: "url(".concat(AnimatedShapes, ")"), backgroundSize: "cover" }, className: "bg-indigo-500 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4" }, { children: [_jsx("div", __assign({ className: "pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20" }, { children: _jsxs("div", __assign({ className: "lg:self-center" }, { children: [_jsx("h2", __assign({ className: "text-2xl font-extrabold text-white sm:text-3xl" }, { children: _jsx("span", __assign({ className: "block" }, { children: "Open Source Domain Tools" }), void 0) }), void 0), _jsx("p", __assign({ className: "mt-4 text-lg leading-6 text-indigo-200" }, { children: "Take advantage of our (growing) open source domain tools to manage your domain portfolio at scale." }), void 0), _jsx("p", __assign({ className: "mt-4 text-lg leading-6 text-indigo-200" }, { children: "Want to add functionality? Code contributions are welcomed with open arms!" }), void 0), _jsxs("a", __assign({ target: '_blank', href: "https://github.com/domainpage/domain-tools", className: "mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50" }, { children: [_jsx(ExternalLinkOutline, { className: 'h4 w-4 mr-2' }, void 0), "View Domain Tools"] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1" }, { children: _jsx("img", { className: "transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-24", src: OpenSourceDomainToolsImage, alt: "DomainPage.io Open Source Domain Tools" }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default OpenSourceBanner;
