var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Popover } from "@headlessui/react";
var Tooltip = function (_a) {
    var button = _a.button, children = _a.children, classes = _a.classes;
    return (_jsxs(Popover, __assign({ className: "relative ".concat(classes) }, { children: [_jsx(Popover.Button, { children: button }, void 0), _jsx(Popover.Panel, __assign({ className: "absolute text-xs z-10 bg-white py-1.5 px-3 border border-gray-300 rounded-md shadow-lg fit-content max-w-3xl" }, { children: children }), void 0)] }), void 0));
};
export default Tooltip;
