var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import PricingSection from "../../components/PricingSection";
import { emitToastNotification } from "../../utils";
import NotificationContext from "../../context/NotificationContext";
import { NotificationType } from "@domainpage/shared/src";
import { ShieldCheckOutline } from "heroicons-react";
import ButtonLoader from "../../components/common/ButtonLoader";
import AuthContext from "../../context/AuthContext";
import Tag from "../../components/Tag";
var stripePromise = loadStripe("pk_test_51Ihfk9B7XZNczzHFC6WQ8xGh3l1NbB7CQJzSHMX2c8yh2MD4ZWUun6PEoNoOJQVSXFLrRKME8n0v5vd5vAOPJr3j00dxYPYehD");
var DashboardBilling = function () {
    var dispatch = useContext(NotificationContext).dispatch;
    var auth = useContext(AuthContext).state;
    var _a = useState(false), stripeCheckoutLoading = _a[0], setStripeCheckoutLoading = _a[1];
    var handleStripePortalClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, data, e_1, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStripeCheckoutLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch("/api/payments/customer-portal", {
                            method: "POST",
                        })];
                case 2:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 3:
                    data = _a.sent();
                    window.location.href = data.url;
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.log("Stripe Portal Error:", e_1);
                    notification = {
                        title: "Stripe Portal Error",
                        message: "".concat(e_1),
                        type: NotificationType.ERROR,
                    };
                    emitToastNotification(dispatch, notification);
                    setStripeCheckoutLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleStripeCheckoutClick = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var stripe, response, session, result, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripePromise];
                case 1:
                    stripe = _a.sent();
                    return [4 /*yield*/, fetch("/api/payments/checkout-session", {
                            method: "POST",
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()
                        // When the customer clicks on the button, redirect them to Checkout.
                    ];
                case 3:
                    session = _a.sent();
                    return [4 /*yield*/, stripe.redirectToCheckout({
                            sessionId: session.id,
                        })];
                case 4:
                    result = _a.sent();
                    if (result.error) {
                        console.log("Stripe Portal Error:", result.error.message);
                        notification = {
                            title: "Stripe Checkout Error",
                            message: "".concat(result.error.message),
                            type: NotificationType.ERROR,
                        };
                        emitToastNotification(dispatch, notification);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: "my-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" }, { children: [_jsx("h2", __assign({ className: "text-lg leading-6 font-medium text-gray-900" }, { children: "Billing" }), void 0), _jsxs("div", __assign({ className: "grid grid-cols-1 sm:grid-cols-2 mt-6 px-4 py-5 sm:p-6 bg-white sm:rounded-lg shadow-md" }, { children: [_jsxs("div", __assign({ className: "sm:grid-cols-1" }, { children: [_jsx("h3", __assign({ className: "text-lg leading-6 font-medium text-gray-900 w-1/2" }, { children: "Manage subscription" }), void 0), _jsx("div", __assign({ className: "mt-2 sm:flex sm:items-start sm:justify-between" }, { children: _jsx("div", __assign({ className: "max-w-xl text-sm text-gray-500" }, { children: _jsxs("p", { children: ["Upgrade or make changes to your current plan:", " ", _jsx(Tag, { color: "green", text: "".concat(auth.user.planType, " ").concat(auth.user.planInterval) }, void 0)] }, void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "sm:grid-cols-1 justify-end mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center" }, { children: _jsxs("button", __assign({ onClick: function () { return handleStripePortalClick(); }, type: "button", className: "inline-flex align-middle items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300" }, { children: [stripeCheckoutLoading ? (_jsx(ButtonLoader, { color: "white" }, void 0)) : (_jsx(ShieldCheckOutline, { className: "-ml-1 mr-3 h-5 w-5" }, void 0)), "Change plan"] }), void 0) }), void 0)] }), void 0), _jsx(PricingSection, { hideButton: true }, void 0)] }), void 0));
};
export default DashboardBilling;
