export var Categories;
(function (Categories) {
    Categories["ADULT"] = "Adult";
    Categories["ADVERTISING_MARKETING"] = "Advertising & Marketing";
    Categories["ARTS"] = "Arts";
    Categories["AUTOMOTIVE"] = "Automotive";
    Categories["BLOCKCHAIN"] = "Blockchain";
    Categories["BRANDABLE"] = "Brandable";
    Categories["BUSINESS"] = "Business";
    Categories["CANNABIS"] = "Cannabis";
    Categories["CAREER_EDUCATION"] = "Career & Education";
    Categories["COMPUTERS_TECHNOLOGY"] = "Computers & Technology";
})(Categories || (Categories = {}));
