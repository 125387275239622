var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { USER_DEFAULT_PAGE, USER_DETAILS, USER_LOGIN, USER_LOGOUT, USER_TIMEZONE, USER_TOKEN_FOUND, } from "../actions";
var authReducer = function (state, action) {
    switch (action.type) {
        case USER_TOKEN_FOUND:
            return __assign(__assign({}, state), { authenticated: true });
        case USER_LOGIN:
            try {
                return __assign(__assign({}, state), { authenticated: true });
            }
            catch (e) {
                return e;
            }
        case USER_LOGOUT:
            return __assign(__assign({}, state), { authenticated: false, user: {}, timezone: null });
        case USER_TIMEZONE:
            return __assign(__assign({}, state), { timezone: action.payload });
        case USER_DETAILS:
            try {
                var user = action.payload;
                return __assign(__assign({}, state), { user: user });
            }
            catch (err) {
                console.log("Error fetching user details:", err);
                return err;
            }
        case USER_DEFAULT_PAGE:
            return __assign(__assign({}, state), { defaultPage: action.payload });
        default:
            return state;
    }
};
export default authReducer;
