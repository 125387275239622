import { Application } from "@domainpage/shared/src/constants/application";
export var ReservedSubdomains = [
    "www",
    "app",
    "domainpage",
    "localhost",
    "local",
    "localhost:3000",
    "marketplace",
    Application.MARKETPLACE_SUBDOMAIN,
    "buy",
    "example",
    "andrea",
    "wheeler",
    "domains",
    "domain",
    "staging-domainpage",
    "dev-domainpage"
];
