var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
}
var Select = function (_a) {
    var items = _a.items, initialValue = _a.initialValue, onChange = _a.onChange;
    var _b = useState(initialValue), selected = _b[0], setSelected = _b[1];
    var handleChange = function (item) {
        onChange(item);
        setSelected(item);
    };
    return (_jsx(Listbox, __assign({ value: selected, onChange: handleChange }, { children: function (_a) {
            var open = _a.open;
            return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "mt-1 relative" }, { children: [_jsxs(Listbox.Button, __assign({ className: "bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" }, { children: [_jsx("span", __assign({ className: "block truncate" }, { children: selected === null || selected === void 0 ? void 0 : selected.display }), void 0), _jsx("span", __assign({ className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }, { children: _jsx(SelectorIcon, { className: "h-5 w-5 text-gray-400", "aria-hidden": "true" }, void 0) }), void 0)] }), void 0), _jsx(Transition, __assign({ show: open, as: Fragment, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Listbox.Options, __assign({ static: true, className: "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" }, { children: items.map(function (item) { return (_jsx(Listbox.Option, __assign({ className: function (_a) {
                                        var active = _a.active;
                                        return classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9');
                                    }, value: item }, { children: function (_a) {
                                        var selected = _a.selected, active = _a.active;
                                        return (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate') }, { children: item === null || item === void 0 ? void 0 : item.display }), void 0), selected ? (_jsx("span", __assign({ className: classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4') }, { children: _jsx(CheckIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0) }), void 0)) : null] }, void 0));
                                    } }), item === null || item === void 0 ? void 0 : item.id)); }) }), void 0) }), void 0)] }), void 0) }, void 0));
        } }), void 0));
};
export default Select;
