export var Paginate;
(function (Paginate) {
    Paginate[Paginate["PAGE"] = 1] = "PAGE";
    Paginate[Paginate["LIMIT"] = 48] = "LIMIT";
    Paginate[Paginate["OFFSET"] = 48] = "OFFSET";
})(Paginate || (Paginate = {}));
export var PageDirection;
(function (PageDirection) {
    PageDirection["NEXT"] = "NEXT";
    PageDirection["PREV"] = "PREV";
})(PageDirection || (PageDirection = {}));
