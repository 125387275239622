var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import PricingSection from "../../components/PricingSection";
var Pricing = function () {
    return (_jsx("div", __assign({ className: "bg-white" }, { children: _jsxs("div", __assign({ className: "max-w-4xl mx-auto py-24 px-4 sm:px-6 lg:px-8" }, { children: [_jsx("h1", __assign({ className: "text-5xl font-extrabold text-gray-900 sm:text-center" }, { children: "Pricing Plans" }), void 0), _jsx("p", __assign({ className: "mt-5 text-xl text-gray-500 sm:text-center" }, { children: "Get started with our forever free plan or dive right into more features!" }), void 0), _jsx(PricingSection, {}, void 0)] }), void 0) }), void 0));
};
export default Pricing;
