var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Logo from "../assets/img/logo.svg";
import { Redirect } from "@domainpage/shared/src";
import AppLink from "./AppLink";
var NotFound = function () {
    return (_jsx("div", __assign({ className: "min-h-screen pt-16 pb-12 flex flex-col" }, { children: _jsxs("main", __assign({ className: "flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8" }, { children: [_jsx("div", __assign({ className: "flex-shrink-0 flex justify-center" }, { children: _jsxs(AppLink, __assign({ to: Redirect.ROOT, className: "inline-flex" }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "DomainPage.io" }), void 0), _jsx("img", { className: "h-12 w-auto", src: Logo, alt: "DomainPage.io Logo" }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "py-16" }, { children: _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("p", __assign({ className: "text-sm font-semibold text-indigo-600 uppercase tracking-wide" }, { children: "404 error" }), void 0), _jsx("h1", __assign({ className: "mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl" }, { children: "Page not found." }), void 0), _jsx("p", __assign({ className: "mt-2 text-base text-gray-500" }, { children: "Sorry, we couldn\u2019t find the page you\u2019re looking for." }), void 0), _jsx("div", __assign({ className: "mt-6" }, { children: _jsxs(AppLink, __assign({ to: Redirect.ROOT, className: "text-base font-medium text-indigo-600 hover:text-indigo-500" }, { children: ["Go back home", _jsx("span", __assign({ "aria-hidden": "true" }, { children: " \u2192" }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default NotFound;
