export var ReservedSubdomains = [
    "api",
    "proxy",
    "www",
    "domainpage",
    "staging-domainpage",
    "dev-domainpage",
    "localhost",
    "local",
    "localhost:3000",
    "marketplace",
    "shop",
    "buy",
    "example",
    "demo",
    "timothy",
    "trw",
    "andrea",
    "wheeler",
    "rigo",
    "domains",
    "domain",
    "lease",
    "finance",
    "purchase",
    "rent",
    "payments",
    "payment",
    "pay",
    "page",
    "pages",
    "landing",
    "landingpage",
    "wholesale",
    "sale",
    "sold",
    "app",
    "auction",
    "auctions",
    "application",
    "dash",
    "dashboard",
    "analytics",
    "brand",
    "bin",
    "brandable",
    "brands",
    "software",
    "hardware",
    "soft",
    "escrow",
    "deals",
    "deal"
];
