export var Sort;
(function (Sort) {
    Sort["LATEST"] = "LATEST";
    Sort["MOST_VIEWS"] = "MOST_VIEWS";
    Sort["HIGHEST_PRICE"] = "HIGHEST_PRICE";
    Sort["LOWEST_PRICE"] = "LOWEST_PRICE";
    Sort["LONGEST"] = "LONGEST";
    Sort["SHORTEST"] = "SHORTEST";
    Sort["BUY_IT_NOW"] = "BUY_IT_NOW";
    Sort["ALPHABETICAL"] = "ALPHABETICAL";
    Sort["EXPIRATION"] = "EXPIRATION";
})(Sort || (Sort = {}));
