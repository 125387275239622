var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import GenericTable from "../../components/table/GenericTable";
import { leadTableColumnSchema } from "../../constants/table/leadTableColumnSchema";
import { Interval, Paginate } from "@domainpage/shared/src";
import { emitToastNotification } from "../../utils";
import NotificationContext from "../../context/NotificationContext";
import DashboardContext from "../../context/DashboardContext";
import AuthContext from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import Stats from "../../components/Stats";
import { ChartSquareBarOutline, ClipboardCheckOutline, EyeOutline, } from "heroicons-react";
var DashboardLeads = function () {
    var _a = useContext(NotificationContext), notification = _a.state, dispatch = _a.dispatch;
    var _b = useContext(DashboardContext), dashboard = _b.state, dashboardDispatch = _b.dispatch;
    var auth = useContext(AuthContext).state;
    var history = useHistory();
    var _c = useState([]), leads = _c[0], setLeads = _c[1];
    var _d = useState(Interval.LIFETIME), interval = _d[0], setInterval = _d[1];
    var _e = useState({}), paginateOptions = _e[0], setPaginateOptions = _e[1];
    var _f = useState(Paginate.PAGE), page = _f[0], setPage = _f[1];
    var _g = useState(Paginate.LIMIT), limit = _g[0], setLimit = _g[1];
    var _h = useState({}), stats = _h[0], setStats = _h[1];
    var fetchLeads = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, notification_1, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/leads")];
                case 1:
                    res = _a.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, res.json()];
                case 2:
                    notification_1 = _a.sent();
                    return [2 /*return*/, emitToastNotification(dispatch, notification_1)];
                case 3: return [4 /*yield*/, res.json()];
                case 4:
                    payload = (_a.sent()).payload;
                    setPaginateOptions(payload);
                    setLeads(payload.docs);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var fetchDomainPortfolioStats = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/domains/portfolio-stats?interval=".concat(interval, "&page=").concat(page, "&limit=").concat(limit))];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    setStats(data.payload);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteLeads = function (leadObjArr) { return __awaiter(void 0, void 0, void 0, function () {
        var leadIdsToBeDeletedArr, params, res, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    leadIdsToBeDeletedArr = leadObjArr.map(function (lead) { return lead.original._id; });
                    params = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ payload: leadIdsToBeDeletedArr }),
                    };
                    return [4 /*yield*/, fetch("/api/leads/delete", params)];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    notification = _a.sent();
                    emitToastNotification(dispatch, notification);
                    history.go(0);
                    return [2 /*return*/];
            }
        });
    }); };
    var initialState = {
        hiddenColumns: [],
        pageIndex: 0,
        pageSize: 0,
    };
    var statsData = [
        { name: "Views", stat: stats.totalViews, icon: _jsx(EyeOutline, { className: "h-6 w-6 text-gray-400" }, void 0) },
        { name: "Leads", stat: stats.totalLeads, icon: _jsx(ClipboardCheckOutline, { className: "h-6 w-6 text-gray-400" }, void 0) },
        {
            name: "Lead Conversion %",
            stat: stats.totalViews ? (stats.totalLeads / stats.totalViews).toFixed(5) : 0,
            icon: _jsx(ChartSquareBarOutline, { className: "h-6 w-6 text-gray-400" }, void 0),
        },
    ];
    useEffect(function () {
        fetchDomainPortfolioStats().catch(function (err) { return err; });
    }, []);
    useEffect(function () {
        fetchLeads().catch(function (err) { return err; });
    }, [interval, page]);
    useEffect(function () { }, [leads]);
    return leads ? (_jsx("div", __assign({ className: "mt-8" }, { children: _jsx("div", __assign({ className: "max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" }, { children: _jsxs("div", __assign({ className: "space-y-6" }, { children: [_jsx(Stats, { data: statsData }, void 0), _jsx("div", __assign({ className: "flex flex-col" }, { children: _jsx("div", __assign({ className: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }, { children: _jsx("div", __assign({ className: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" }, { children: _jsx(GenericTable, { data: leads, initialState: initialState, selectableRowTableOptions: { deleteCallback: handleDeleteLeads, entity: { singular: "Lead", plural: "Leads" } }, columnSchema: leadTableColumnSchema, paginateOptions: paginateOptions, pageCallback: setPage }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)) : null;
};
export default DashboardLeads;
