var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import AppLink from "../../components/AppLink";
import CurrencyCell from "../../components/table/CurrencyCell";
import { LeadHeader } from "@domainpage/shared/src/constants/headers";
import { Redirect } from "@domainpage/shared/src/constants/menu";
export var leadTableColumnSchema = [
    {
        id: LeadHeader.DOMAIN_COLUMN,
        Header: LeadHeader.DOMAIN_DISPLAY,
        accessor: LeadHeader.DOMAIN_COLUMN,
        Cell: function (_a) {
            var cell = _a.cell;
            return (_jsx(AppLink, __assign({ className: "w-full h-full", to: "".concat(Redirect.DOMAINS, "/").concat(cell.value) }, { children: _jsx("p", __assign({ className: "font-medium" }, { children: cell.value }), void 0) }), void 0));
        }
    },
    {
        id: LeadHeader.NAME_COLUMN,
        Header: LeadHeader.NAME_DISPLAY,
        accessor: LeadHeader.NAME_COLUMN,
    },
    {
        id: LeadHeader.EMAIL_COLUMN,
        Header: LeadHeader.EMAIL_DISPLAY,
        accessor: LeadHeader.EMAIL_COLUMN,
    },
    {
        id: LeadHeader.PHONE_COLUMN,
        Header: LeadHeader.PHONE_DISPLAY,
        accessor: LeadHeader.PHONE_COLUMN,
    },
    {
        id: LeadHeader.MESSAGE_COLUMN,
        Header: LeadHeader.MESSAGE_DISPLAY,
        accessor: LeadHeader.MESSAGE_COLUMN,
    },
    {
        id: LeadHeader.OFFER_COLUMN,
        Header: LeadHeader.OFFER_DISPLAY,
        accessor: LeadHeader.OFFER_COLUMN,
        Cell: CurrencyCell
    },
];
