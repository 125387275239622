var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classNames } from "../../utils";
var PillGroup = function (_a) {
    var items = _a.items, selectedCallback = _a.selectedCallback, itemsCallback = _a.itemsCallback;
    var handleClick = function (selectedItem) {
        var updatedItems = __spreadArray([], items, true).map(function (item) {
            item.current = item.value === selectedItem;
            return item;
        });
        selectedCallback(selectedItem);
        itemsCallback(updatedItems);
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "sm:hidden" }, { children: [_jsx("label", __assign({ htmlFor: "tabs", className: "sr-only" }, { children: "Select a tab" }), void 0), _jsx("select", __assign({ id: "tabs", name: "tabs", className: "block text-xs border border-gray-300 w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md", onChange: function (e) { return handleClick(e.target.value); }, defaultValue: items.find(function (tab) { return tab.current; }).title }, { children: items.map(function (tab) { return (_jsx("option", __assign({ value: tab.value }, { children: tab.name }), tab.name)); }) }), void 0)] }), void 0), _jsx("div", __assign({ className: "hidden sm:block" }, { children: _jsx("nav", __assign({ className: "flex space-x-4", "aria-label": "Tabs" }, { children: items.map(function (tab) { return (_jsxs("button", __assign({ onClick: function () { return handleClick(tab.value); }, className: classNames(tab.current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'bg-gray-50 px-3 py-1 font-medium text-xs rounded-md inline-flex align-middle border border-gray-300 truncate'), "aria-current": tab.current ? 'page' : undefined }, { children: [tab.icon ? _jsx("span", { children: tab.icon }, void 0) : null, tab.name] }), tab.name)); }) }), void 0) }), void 0)] }, void 0));
};
export default PillGroup;
