var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ExternalLink } from "heroicons-react";
import React from "react";
import AppLink from "../AppLink";
var ExternalLinkCell = function (_a) {
    var row = _a.row;
    var domain = row.original.name;
    var username = row.original.username;
    return (_jsx(AppLink, __assign({ className: "text-center w-full h-full flex items-center justify-center", newTab: true, subdomain: username, to: "/".concat(domain) }, { children: _jsx(ExternalLink, { className: "h-4 w-4 text-center text-gray-400" }, void 0) }), void 0));
};
export default ExternalLinkCell;
