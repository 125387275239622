var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var LegalFooter = function () {
    return (_jsx("div", __assign({ className: "relative bg-white border-t border-gray-200 py-8" }, { children: _jsxs("p", __assign({ className: "text-xs text-center leading-6 text-gray-400" }, { children: ["\u00A9 ".concat(new Date().getFullYear()), " DomainPage.io | All Rights Reserved |", " ", _jsx("a", __assign({ href: "https://".concat(process.env.REACT_APP_ROOT_URL, "/privacy-policy"), target: "_blank" }, { children: "Privacy Policy" }), void 0), " ", "|", " ", _jsx("a", __assign({ href: "https://".concat(process.env.REACT_APP_ROOT_URL, "/cookie-policy"), target: "_blank" }, { children: "Cookie Policy" }), void 0), " ", "|", " ", _jsx("a", __assign({ href: "https://".concat(process.env.REACT_APP_ROOT_URL, "/acceptable-use-policy"), target: "_blank" }, { children: "Acceptable Use Policy" }), void 0), " ", "|", " ", _jsx("a", __assign({ href: "https://".concat(process.env.REACT_APP_ROOT_URL, "/terms-of-service"), target: "_blank" }, { children: "Terms of Service" }), void 0)] }), void 0) }), void 0));
};
export default LegalFooter;
