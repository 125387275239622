var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ExclamationCircleOutline } from "heroicons-react";
var Alert = function (_a) {
    var _b = _a.title, title = _b === void 0 ? "NOTE:" : _b, text = _a.text, _c = _a.color, color = _c === void 0 ? "yellow" : _c, _d = _a.icon, icon = _d === void 0 ? _jsx(ExclamationCircleOutline, { className: "h-5 w-5 text-".concat(color, "-400") }, void 0) : _d;
    return (_jsx("div", __assign({ className: "bg-".concat(color, "-50 border-l-4 border-").concat(color, "-400 p-4") }, { children: _jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ className: "flex-shrink-0" }, { children: icon }), void 0), _jsx("div", __assign({ className: "ml-3" }, { children: _jsxs("p", __assign({ className: "font-bold text-sm text-".concat(color, "-700") }, { children: [title, " ", _jsx("p", { className: "font-normal text-".concat(color, "-700 hover:text-").concat(color, "-600"), dangerouslySetInnerHTML: { __html: text } }, void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default Alert;
